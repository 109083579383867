.expandSearchCell {
  overflow: hidden;
  white-space: nowrap;
  width: 220px;
  text-overflow: ellipsis;
}

.expandSearchCell:hover {
  overflow: visible;
  white-space: normal;
}