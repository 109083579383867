.job-delivery-detail-tabs-container > .nav-tabs {
  border-bottom: 0;
}

.job-delivery-detail-tabs-container > .nav-tabs > li > a {
  color: #444;
  border: 0;
  font-weight: 600;
  font-size: 14px;
}

.job-delivery-detail-tabs-container > .nav-tabs > li > a:hover {
  background: none;
}

.job-delivery-detail-tabs-container > .nav-tabs > li.active > a {
  color: #337ab7;
  background: none;
  border: 0;
  cursor: pointer;
}

#job-delivery-detail-tab .tab-content{
  background: #ffffff;
  padding: 10px;
  margin-top: -1px;
}
#job-delivery-detail-tab > .tab-content{  
  border: 1px solid #bdc3c7;
}

.navigation-box {
  border: 0;
  background: rgba(41, 158, 41, 0.07);
  border-radius: 3px;
}
.navigation-box .box-header {
  background:#00a65a;
  color: #ffffff;
  border-radius: 3px 3px 0 0;
}
.navigation-box .box-footer {
  border-top: 0;
  background: transparent;
}
