#root .login-page {
  background-image: url(../images/tokyo3980.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-logo {
  margin-bottom: 30px;
}

.login-logo-text {
  font-weight: 200;
  color: rgba(26, 100, 192, 1.00);
  font-size: 28px;
}

.login-box-body {
  position: relative;
}

.login-box-body .form-control {
  height: 34px;
}