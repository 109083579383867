/*global tabs*/
#global-tabset > .tab-content > .tab-pane {
  /* overflow: hidden; */
}
.content-wrapper .nav-tabs li{
  display: inline-block;
  float: none;
}
#global-tabset > .nav-tabs .tab-remove {
  margin-left: 10px;
  cursor: pointer;
}
#global-tabset > .nav-tabs .tab-title {
  margin-right: 10px;
}
#global-tabset > .nav-tabs li {
  margin-bottom: 0;
  margin-top: 3px;
}
#global-tabset > .nav-tabs li.active a {
  background-color: #ecf0f5;
  color: #299e29;
  border-color: #ecf0f5;
  font-weight: 600;
}
#global-tabset > .nav-tabs li a {
  padding: 5px 15px;
  color: #b8c7ce;
  border-radius: 0;
  background-color: rgba(34, 45, 50, 0.58);
  margin-right: 5px;
}
#global-tabset > .nav-tabs li a:hover {
  color: #444;
  background-color: #ecf0f5;
  border-color: #ecf0f5;
}
#global-tabset .tab-close {
  cursor: pointer;
}
#global-tabset .label-default {
  background-color: rgba(0, 0, 0, 0.1);
}
#global-tabset .content .nav-tabs {
  border-bottom: 0;
}
#global-tabset .content .nav-tabs li.active a .label-default {
  background-color: rgba(41, 158, 41, 1.0);
  color: #fff;
}
#global-tabset .border-tab-content-page > .tab-content{
  /*use ".border-tab-content-page.content " for page with .tab-content that need border in the content*/
  border:1px solid #bdc3c7;
  margin-top: -1px;
  background: #ffffff;
}

.tab-content .content .nav-tabs li a {
  margin-top: 2px;
  border: 1px solid #bdc3c7;
  background: linear-gradient(#dddfe2, #dddfe2, #dddfe2, #dddfe2, #dddfe2, #ccc);
  color: #000000;
  padding: 2px 15px 7px;
}
.tab-content .content .nav-tabs li.active a {
  margin-top: 0;
  border: 1px solid #bdc3c7;
  /* border-color: #bdc3c7; */
  border-bottom: 0;
  border-top: 4px solid #299e29;
  background: #ffffff;
  color: rgba(41, 158, 41, 1.0);
}
.tab-content .content .nav-tabs-custom .nav-tabs{/*re-stye tab in tab*/
  box-shadow: 0px 8px 15px -10px rgba(0,0,0,0.2);
  border-bottom: 1px solid #dfdfdf;
  margin: 0 0 25px;
  padding: 8px 0 0 0;
}
.tab-content .content .nav-tabs-custom > .nav-tabs > li{
  margin: 0 15px;
  margin-bottom: -5px;
  z-index: 2;
  font-size: 1.15em;
}
.tab-content .content .nav-tabs-custom .nav-tabs li a{
  background: none;
  padding: 0 2px 5px 2px;
  border:0;
}
.tab-content .content .nav-tabs-custom .nav-tabs li.active {
  border-bottom: 5px solid #299e29;
}

/*@kapost teb slider module*/
.wrapper,
.fixed .wrapper {
  overflow: inherit;/*reset overflow here or sticky in ".component-slider" won't work*/
}
.component-slider {
  position: sticky;
  top: 50px;
  z-index: 100;
  background-color: rgba(26, 34, 38, 0.95);
}
.component-slider .caret {
  height: 100%;
  width: 15px;
  background-color: rgba(41, 158, 41, 1.0);
  color:#ffffff;
}
.component-slider .caret:hover {
  background-color: rgba(56, 191, 107, 0.90);
}

.component-slider .slider-container {
  margin: 0 15px;
  font-size: 0; /*or display: flex; + remove font-size in ".component-slider .slider-content"*/
}

.component-slider .slider-content {
  font-size: 14px;
}

.component-slider .slider-content > .nav-tabs {
  border-bottom: 0;
}

.component-slider .nav-tabs li a {
  margin-top: 4px;
  border: 0;
  border-radius: 3px 3px 0 0;
  color: #aeb6bc;
  padding: 7px 15px;
}
.component-slider .nav-tabs li a:hover {
  background-color: rgba(255, 255, 255, 0.15);
  color: #ccc;
}

.component-slider .nav-tabs li.active a,
.component-slider .nav-tabs li.active a:hover,
.component-slider .nav-tabs li.active a:focus {
  background-color: #ecf0f5;
  color: rgba(41, 158, 41, 1.0);
  font-weight: 600;
  border:0;
}
