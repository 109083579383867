/*
* ==============================================================================
* quilljs
* ==============================================================================
*/

.ql-snow .ql-color-picker .ql-picker-item {
  border-color: #eeeeee;
}

.ql-toolbar.ql-snow {
  border-top-width: 0;
  border-right-width: 0;
  border-left-width: 0;
  border-bottom: 1px solid #f4f4f4;
}

.ql-container.ql-snow {
  border-width: 0;
}

.ql-container.ql-snow .ql-editor {
  min-height: 200px;
}

.has-error .ql-toolbar.ql-snow,
.has-error .ql-container.ql-snow {
  border-color: #dd4b39;
  border-width: 1px;
}

.form-editor {
  margin-bottom: 0!important;
}

