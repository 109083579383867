.ta-input-dropzone {
    height: 200px;
    background-size: 100px auto;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg version='1.1' viewBox='0 0 106 80' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(2 2)'%3E%3Cpath id='a' d='m70.2 65.7h11v-0.1c11.7-0.8 20.8-10.4 20.8-22.3 0-10.3-7-19-16.5-21.5-2.4-12.2-13.2-21.5-26.2-21.5-10.1 0-18.8 5.6-23.4 13.8-1.6-0.5-3.3-0.7-5-0.7-9 0-16.5 6.7-17.7 15.4-7.7 2.4-13.2 9.5-13.2 18 0 10.4 8.5 18.9 18.9 18.9h13.2' stroke='%2390A4AE' stroke-width='3'/%3E%3Cg transform='translate(40 28)'%3E%3Cpath d='m12.2 3.6v45.5' stroke='%2390A4AE' stroke-width='3'/%3E%3Cpolygon points='0 13.5 2.2 15.6 12.2 4.7 22.3 15.6 24.5 13.5 12.2 0.3' fill='%2390A4AE' fill-rule='nonzero'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    background-color: #CFD8DC;
    background-position: center 35%;
    background-repeat: no-repeat;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;

    color: #90A4AE;

}

.ta-input-dropzone__placeholder,
.ta-input-dropzone .file-upload .text-center
{
    margin-top: 100px;
    font-size: 18px;
    text-align: center;
}

.ta-input-dropzone:hover {
    color: #FFF;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg version='1.1' viewBox='0 0 106 80' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(2 2)'%3E%3Cpath id='a' d='m70.2 65.7h11v-0.1c11.7-0.8 20.8-10.4 20.8-22.3 0-10.3-7-19-16.5-21.5-2.4-12.2-13.2-21.5-26.2-21.5-10.1 0-18.8 5.6-23.4 13.8-1.6-0.5-3.3-0.7-5-0.7-9 0-16.5 6.7-17.7 15.4-7.7 2.4-13.2 9.5-13.2 18 0 10.4 8.5 18.9 18.9 18.9h13.2' stroke='%23FFF' stroke-width='3'/%3E%3Cg transform='translate(40 28)'%3E%3Cpath d='m12.2 3.6v45.5' stroke='%23FFF' stroke-width='3'/%3E%3Cpolygon points='0 13.5 2.2 15.6 12.2 4.7 22.3 15.6 24.5 13.5 12.2 0.3' fill='%23FFF' fill-rule='nonzero'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    background-color: #357CA5;
}
