.ta-tab,
.ta-tab-group {
    overflow: visible;
    position: relative;
}

.ta-tab-group {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
    box-sizing: border-box;
    flex-direction: column;
}

.ta-tab-group--flex {
    flex: 1 0 100%;
    max-height: 100%;
}

.ta-tab-scroll {
    overflow-x: visible;
    overflow-y: scroll;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

.ta-tab-group__head {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: center;
    background: transparent;
    border-bottom: 1px solid #ced7db;
    height: 48px;
}

.ta-tab-group--flex > .ta-tab-group__head + .ta-tab {
    flex: 1;
    max-height: calc(100% - 32px);
}

.ta-tab-label {

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-weight: normal;

    padding: 3px;
    padding-bottom: 0;
    height: 48px;

    box-sizing: border-box;

    background: transparent;
    border: 0;
    border-bottom: 3px solid rgba(33, 150, 243, 0);
    border-bottom: 3px solid rgba(33, 150, 243, 0);

    outline: none;
    color: #263238;
    cursor: pointer;
    user-select: none;

    transition: all 0.3s;

    position: relative;

    text-align: center;


}

.ta-tab-label {

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-weight: normal;

    padding: 4px;
    /*padding-bottom: 0;*/
    height: 100%;

    box-sizing: border-box;

    background: transparent;
    border: 0;
    /*border-bottom: 3px solid rgba(33, 150, 243, 0);*/
    /*border-bottom: 3px solid rgba(33, 150, 243, 0);*/

    outline: none;
    color: #263238;
    cursor: pointer;
    user-select: none;

    transition: all 0.3s;

    position: relative;

    text-align: center;
}
.ta-tab-label--disabled {
    color: #b0bec5;
}

.ta-tab-label > span {
    font-size: 14px;
    line-height: 14px;
    padding: 0 8px;
}

.ta-tab-label > .ta-icon {
    order: 2
}

.ta-tab-label > span {
    order: 1
}


.ta-tab-label:not(.ta-tab-label--disabled):hover,
.ta-tab-label--active {
    color: rgba(33, 150, 243, 1);
}

/*
.ta-tab-label:hover {
    color: rgba(33, 150, 243, 1);
}

.ta-tab-label--active {
    color: rgba(33, 150, 243, 1);
    border-color: #2196f3;
}
*/

.ta-tab-label:after {
    display: none;
    position: absolute;
    bottom: -1px;
    left: 12px;
    right: 12px;
    height: 4px;
    content: '';
    background-color: rgba(33, 150, 243, 1);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    z-index: 1;
}
.ta-tab-label--active:after {
    display: block;
}

.ta-tab-group--compact .ta-tab-group__head {
    height: 37px;
}

.ta-tab-group--grey .ta-tab-group__head {
    background: #f5f6f7;
}
