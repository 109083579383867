.ta-input--dropdown {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: stretch;
    padding: 0;
    height: 32px;


    border: 1px solid #ced7db;
    box-shadow: 0 0 0 rgba(33, 149, 242, 0);

    transition: border 0.3s, box-shadow 0.3s;
}

.ta-input--dropdown {
    border-radius: 4px;
    background: white;
    /*background: rgba(206, 215, 219, 0.25);*/
}


.ta-input--dropdown.ta-input--focus {
    box-shadow: 0 0 5px rgba(33, 149, 242, 1) /* #03a9f4 */;
    border: 1px solid #03a9f4;
}

.ta-input--dropdown > select {
    /*position: absolute;*/
    /*display: block;*/
    /*top: 0;*/
    /*left: 0;*/
    /*right: 0;*/
    /*bottom: 0;*/
    border: 0;
    width: 100%;
    margin: 0;
    padding: 0 32px 0 8px;
    /*padding: 0 4px;*/

    background: transparent;
    outline: none;
    /*color: transparent;*/
    cursor: pointer;
    /*user-select: none;*/
    color: #222d32;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;

    -o-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    z-index: 1;
}

.ta-input--dropdown.ta-input--focus .ta-input__icon {
    color: #03a9f4;
}

.ta-input--dropdown .ta-input__icon {
    margin: 3px 4px;
}

.ta-input--dropdown .ta-input__icon:first-child {
    margin-right: 0;
}

.ta-input--dropdown .ta-input__label {
    display: none;
    padding: 0 4px 0 8px;
    order: 1;
    flex: 1;
    max-width: calc(100% - 24px);
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 24px;
}

.ta-input--dropdown .ta-input__icon:last-child {
    position: absolute;
    right:0;
    z-index: 0;
}

.ta-input--dropdown--list > select {
    padding: 8px;
}


