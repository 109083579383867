
.ta-form-messages,
.ta-form-message {
    list-style: none;
    margin: 0;
    padding:0;
}
.ta-form-messages {
    font-size: 0;
    line-height: 0;
}
.ta-form-message {
    font-size: 14px;
    line-height: 14px;
    padding: 8px 16px;
}
.ta-form-message--error {
    color: white;
    background: #e53935;
}

