.main-sidebar > .sidebar {
  height: calc(100vh - 40px);
}

.sidebar-mini:not(.sidebar-collapse) .main-sidebar > .sidebar {
  overflow: auto;
}
.sidebar-mini.sidebar-collapse .main-sidebar > .sidebar {
  overflow: unset;
}

.main-sidebar > .sidebar > .sidebar-menu li > a > .pull-right-container > .fa-angle-left {
  transition: transform 0s ease;
}

/* .main-sidebar > .sidebar > .sidebar-menu li > a {
  padding-left: 7px;
}

.main-sidebar > .sidebar > .sidebar-menu .treeview-menu {
  padding-left: 20px;
} */

.main-sidebar > .sidebar > .sidebar-menu > li.menu-open > a {
  color: #b8c7ce;
  background-color: #222d32;
}

.main-sidebar > .sidebar > .sidebar-menu > li.active > a {
  color: #fff;
  background-color: #1e282c;
}



.sidebar-mini.sidebar-collapse .main-sidebar > .sidebar > .sidebar-menu li > a > .fa-angle-left,
.sidebar-mini.sidebar-collapse .main-sidebar > .sidebar > .sidebar-menu li > a > .pull-right-container > .fa-angle-left {
  transform: rotate(-90deg);
}