a, u {
  cursor: pointer;
}
textarea {
  resize: vertical;
}
/*
* ==============================================================================
* Bootstrap
* ==============================================================================
*/
.text-danger {
  color: #ff0000;
}
.form-group {
  margin-bottom: 10px;
}
.form-condensed .form-group {
  margin-top: 0;
  margin-bottom: 5px;
}

.form-horizontal .control-label {
  margin-bottom: inherit;
  padding-top: inherit;
}

.form-control,
.form-group-sm .form-control,
.input-group-sm > .form-control {
  border-radius: 0;
  height: 24px;
  padding: inherit;
  padding-left: 10px;
}

.table {
  margin-bottom: 0;
}
.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  vertical-align: middle;
}

.modal {
  background: rgba(0, 0, 0, 0.1);
}

.modal-backdrop.in {
  opacity: 0;
}

.modal-body img {
  max-width: 100%;
}

.modal-content {
  border-radius: 3px;
}

#job-invoice-tabs .table-layout-fixed td{
  overflow: auto;
  white-space: normal;
}

/*
* ==============================================================================
* AdminLTE
* ==============================================================================
*/
body {
  background-color: #ecf0f5;
}

.wrapper,
.fixed .wrapper {
  overflow: inherit;
}

.sidebar-menu > li.menu-open > a {
  color: #b8c7ce
}
.sidebar-menu > li.menu-open.active > a {
  color: #fff;
}
.input-group-addon {
  padding: 0 12px;
}

.input-group-sm > .input-group-btn > .btn {
  height: 24px;
  padding: 0px 15px;
}
.input-group-btn:last-child > .btn {
  border-radius: 0;
}
.btn-box-tool:hover {
  background: rgba(0, 0, 0, 0.1);
}
.alert-warning {
  color: #8a6d3b !important;
  background-color: #fcf8e3 !important;
  border-color: #faebcc !important;
}
.nav-tabs-custom > .tab-content {
  padding: 0;
}
.nav-tabs-custom > .tab-content .table > tbody > tr:first-child th, .nav-tabs-custom > .tab-content .table > tbody > tr:first-child td {
  border-top: 0;
}
.nav-tabs-custom > .nav-tabs > li.active {
  border: 0;
}
.box-header, .modal-header, .modal-footer {
  background: rgba(0, 0, 0, 0.02);
}

.box.box-solid.box-success .no-padding {
  /*without this, Some table border in chrome will be disappeared when browser zoom out*/
  padding:1px !important;
}

.Select-arrow-zone {
  background: #f4f4f4;
  padding-left: 6px;
  padding-right: 0;
}

.checkbox.disabled label {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
}

.bg-green .box-footer, .bg-green-light .box-footer {
  color: #333;
  background: #fff;
}

/*
* ==============================================================================
* React Select
* ==============================================================================
*/
.Select.is-open, .Select-control,
.Select-control {
  height: inherit; /*1*/
  border-radius: 0;
  border-color: #d2d6de;
  box-sizing: border-box;
  overflow: inherit;
}
.Select-control .Select-input {
  height: 24px; /*1*/
}
.Select-placeholder, .Select--single > .Select-control .Select-value {
  line-height: 24px; /*1*/
}
.Select-control .Select-input > input {
  line-height: 15px;
  padding: 6px 0 12px;
}
.Select-clear {
  font-size: 16px;
  line-height: 1.4;
}
.Select.has-value.Select--single > .Select-control .Select-value .Select-value-label,
.Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
  color: #555;
  font-size: 12px;
}
.Select .Select-menu-outer {
  font-size: 12px;
  z-index: 10000;
}
.Select--multi .Select-value {
  margin-top: 2px;
  margin-bottom: 2px;
}
/*
* ==============================================================================
* React Date picker
* ==============================================================================
*/
.react-datepicker-popper {
  z-index: 10;
}
/*
* ==============================================================================
* Label Group
* ==============================================================================
*/
label {
  margin-bottom: 2px;
}
.label-group {
  display: inline-block;
  padding: .5em .6em;
}
/*
* ==============================================================================
* SalesTarget
* ==============================================================================
*/
.saleTargetPage .ag-body-container {
  text-align: right;
}
.salestarget-meet {
  font-weight: 700;
  font-size: 1.2em;
}


.pagination {
  margin: 0;
  float: right;
}


.modal-dialog .carousel-indicators {
  visibility: hidden;
}