.job-work-flow {
  display: flex;
  flex-wrap: wrap;
  margin-right:-10px; 
}
.job-work-flow .box {
  margin-bottom: 20px;
  width: 24%;
  align-self: flex-start;
}

.job-work-flow .box:not(:last-child) {
  margin-right: 1%;
}

.job-work-flow .box-header {
  background: transparent;
}

.job-work-flow .box-header .box-title {
  font-size: 17px
}

.job-work-flow .widget-user-2 .box-header .box-title {
  font-size: 16px;
}

.job-work-flow .widget-user-2 .widget-user-header {
  padding: 0;
}

.job-work-flow .widget-user-2 .widget-user-image > img {
  width: 35px;
  margin: 0 10px 10px;
}

.job-work-flow .widget-user-2 .widget-user-username,
.job-work-flow .widget-user-2 .widget-user-desc {
  margin-left: 40px;
}

.job-work-flow .widget-user-2 .widget-user-username {
  margin-top: 3px;
  font-size: 12px;
  font-weight: normal;
}

.job-work-flow .widget-user-2 .widget-user-desc {
  font-size: 11px;
}

.job-work-flow .box-footer .nav-stacked .file-name {
  width: 80%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}


@media (max-width: 768px) {
  .job-work-flow .box {
    width: 100%;
  }
}

@media (max-width: 992px) {
  .job-work-flow .box {
    width: 100%;
  }
}
