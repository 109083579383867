.ta-text--light {
    color: #b0bec5;
}
.ta-text--alert {
    color: #e53935;
}
.ta-text--warn {
    color: #fb8c00;
}
.ta-text--confirm {
    color: #43a047
}
