.navbar {
  min-height: 41px;
  margin-bottom: 14px;
}
.navbar-brand {
  padding-top: 12px;
  padding-bottom: 0;
  height: auto;
}
.tab-content .navbar-nav > li > a {
  padding-top: 9px;
  padding-bottom: 0;
}
.navbar-form {
  padding: 0;
  margin: 7px 0 0;
}

.navbar-inverse .navbar-nav > .disabled > a,
.navbar-inverse .navbar-nav > .disabled > a:hover,
.navbar-inverse .navbar-nav > .disabled > a:focus {
  color: #fff;
}
