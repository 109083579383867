.ta-card {
    background: white;
    border: 1px solid rgb(144, 164, 174);
    border-radius: 4px;
    box-shadow: 0 3px 1px -2px rgba(144, 164, 174,.2), 0 2px 2px 0 rgba(144, 164, 174,.14), 0 1px 5px 0 rgba(144, 164, 174,.12);
    max-height: 100%;
    overflow: hidden;
    background-clip: padding-box;
}

.ta-card,
.ta-card-header,
.ta-card-body,
.ta-card-footer {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
    box-sizing: border-box;
    flex-direction: column;
}

.ta-card-header,
.ta-card-footer {
    background: #f5f6f7;
}
.ta-card-header {
    font-weight: bold;
    margin-bottom: 0;
}
.ta-card-body {
    flex: 1;
    overflow: visible;
}
.ta-card-footer {
    color: #b0bec5;
}

.ta-card > .ta-card-header {
    border-bottom: 1px solid rgb(144, 164, 174);
    border-radius: 4px 4px 0 0;
}
.ta-card > .ta-card-footer {
    border-top: 1px solid rgb(144, 164, 174);
    border-radius: 0 0 4px 4px;
}

.ta-card--flex {
    flex: 1;
}

.ta-card--scroll,
.ta-card-scroll-y,
.ta-card--scroll > .ta-card-body,
.ta-card-scroll-y > .ta-card-body {
    overflow-x: visible;
    overflow-y: scroll;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    max-height: 100%;

}

/*

.ta-card > .ta-table,
.ta-card-body > .ta-table {
    border-radius: 4px;
}

.ta-card > .ta-table > .ta-table-header,
.ta-card-body > .ta-table > .ta-table-header {
    border-bottom: 1px solid rgb(144, 164, 174);
    border-radius: 4px 4px 0 0;
}
.ta-card > .ta-table > .ta-table-footer,
.ta-card-body > .ta-table > .ta-table-footer {
    border-top: 1px solid rgb(144, 164, 174);
    border-radius: 0 0 4px 4px;
}
 */
