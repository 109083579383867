#global-tabset-pane-Calendar .content{
  min-height: 100vh; /*fix "more" popup-box in the last row being cut off if content heigh is not tall enough.*/
}
.fc-view-container {
  height: 100%;
}
.fc .fc-body td {
  border-bottom: 0 !important;
}
.fc-day-grid-event {
  text-align: center;
  margin: 0 3px 2px;
}
.fc-day-grid-event .fc-content {
  padding: 0.2rem 0.25rem 0.3rem;
  white-space: normal;
  line-height: 1.25em;
}
.fc-day-grid-event .fc-time {
  font-weight: normal !important;/* display: block; */
}
.fc-day-grid-event .fc-title {
  font-weight: bold !important;
}
.fc-view, .fc-view > table {
  /*override default "MORE" link to bring popup tootip ABOVE everything*/
  z-index: auto !important;
}
.fc-head th.fc-widget-header {
  color: #8b9898;
  padding: 5px 0;
}
.fc-head th.fc-widget-header.fc-sun {
  color: #ff7878;
}
.fc-head th.fc-widget-header.fc-sat {
  color: #4797fa;
}
.fc td.fc-more-cell{
  padding-top: 2px;
}
a.fc-more {
  background-color: #f4f4f4;
  color: #444;
  border: 1px solid #acacac;
  border-radius: 3px;
  padding: 1px 8px 2px;
  font-size: 0.83em !important;
}
a.fc-more:hover {
  background: #e7e7e7;
  text-decoration: none !important;
}
.fc-event {
  font-size: 1em;
  border-radius: 24px;
  border-color: rgba(255,255,255,0.4);
}
.fc-event:hover {
  filter: brightness(105%);
}


.fc-event.event-assignment-finished{
  background-color: #e2e4ea;
  border-color: #e2e4ea;
  color: #333;
}
.fc-event.event-assignment-expired {
  background-color: #605ca8;
}
.fc-event.event-assignment-created{
  background-color: #ffffff;
  color: #333;
  border-color: rgba(0,0,0,0.25);
}

.fc-event.event-holiday{
    color: #333;
    border-color: #fad16a;
}
.event-holiday-requested{
  background-image: repeating-linear-gradient(145deg, #ffe6a6, #ffe6a6 5px, #fad16a 5px, #fad16a 8px);
}
.event-holiday-approved{
  background:#fad16a;
}
.event-holiday-declined{
  background-image: repeating-linear-gradient(0deg, #fff3d3, #fff3d3 5px, #fff 5px, #fff 8px);
}
a.holiday-available-for-work{
  background: rgba(205,238,157,0.5);
  color: #4a6337;
  border-color: #92c46d;
}
a.holiday-available-for-work:hover{
  background: rgba(205,238,157,0.65);
  color: #4a6337;
}
.fc-agendaWeek-view .fc-event, .fc-agendaDay-view .fc-event {
  border-radius: 3px;
}
.fc-basic-view .fc-body .fc-row {
  height: 100%;
  min-height: 6em;
}
.fc-event .fc-bg {
  opacity: 0 !important;
}
.fc .fc-axis {
  padding: 0 !important;
}
.calendar-content {
  background: #ffffff;
  display: flex;
}
.calendar-content .fc-unthemed {
  margin-top: 0;
  flex: 7;
}
.calendar-user {
  vertical-align: top;
  text-align: center;
  font-size: 0.95em;
  line-height: 1.2em;
  font-weight: 200;
  border-right: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
  flex:0.5 1;
  padding: 0 5px;
  word-break: break-all;
  box-shadow: inset -15px 0px 16px -21px rgba(0,0,0,0.90);
}
.has-header.calendar-user {
  padding-top: 20px;
}
.fc-event.dayoff-available {
  font-weight: 700 !important;
  color: #666666 !important;
  background: rgba(255, 255, 255, 0.25) !important;
  border: 0 !important;
}
.dayoff-box {
  color: #808080 !important;
  border-color: #999 !important;
  background-color: #ccc !important;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 1.5rem 1.5rem !important;
}
.calendar-content .user-image {
  width: 40px;
  height: 40px;
  display: block;
  border: 4px solid #d4d4d4;
  margin: 8px auto 3px;
}
.calendar-content .user-image.available-now {
  border-color: #00a65a;
}
.calendar-controler {
  padding: 8px 5px;
  background: #ffffff;
}
.calendar-controler .btn {
  padding: 0 20px;
  font-size: 24px;
}
.calendar-user .btn {
  margin-bottom: 10px;
  margin-top: 3px;
}
 @-moz-document url-prefix() {
 .fc .fc-body td {
 height: 80%;
}
 .fc .fc-body .fc-bg td {
 height: auto;
}
}
.fc-view table {
  height: 100%;
}
.fc-basic-view {
  height: 100%;
}
.calendar-content .fc-basic-view .fc-head td {
  height: 20px;
  line-height: 20px;
}
.calendar-content .fc-day-grid-container {
  height: 100% !important;
  overflow: visible !important;
}
.fc-scroller.fc-day-grid-container {
  height: auto!important;
}
.fc-day-grid {/* remove height 100% for firfox - user calendar "week" and "day"*/
/* height: 100%; */
}
.user-profile-link {
  display: block;
}
.fc-toolbar.fc-header-toolbar {
  margin: 0;
  padding-top: 0;
  padding-bottom: 4px;
}
.fc-toolbar.fc-header-toolbar h2 {
  font-size: 1.65em;
}
.fc-state-down, .fc-state-active {
  box-shadow: none;
  background-color: #e9e9e9;
}
.fc-bgevent {/*without this, weekend background will be green*/
  background-color: #d7d7d7;
}
.fc-unthemed .fc-popover {
  background-color: rgba(255, 255, 255, 0.85);
  box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14),0 9px 46px 8px rgba(0,0,0,0.12),0 11px 15px -7px rgba(0,0,0,0.2);
  border-color: #aaaaa9;
  border-radius: 4px;
  z-index: 5;
}
.fc-more-popover .fc-event-container{
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 75vh;
  padding: 8px 3px;
}
.fc-unthemed .fc-popover .fc-header{
  background: #dddfe2;
  min-height: 31px;
  line-height: 30px;
  border-radius: 4px 4px 0 0;
  font-weight: 600;
}
.fc-unthemed .fc-popover .fc-header .fc-close{
  margin-top: 10px;
  font-weight: 400;
}
.fc-unthemed td.fc-today{
  background: #e7ffe9;
}