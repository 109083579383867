.ReactModal__Body--open {
    position: absolute;
    top: 0;
    left: 0;
    right:0;
    bottom:0;
    height: 100%;
    height: 100vh;
    overflow: hidden;
}


.ta-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.32);
    z-index: 999;
}

.ta-modal {
    position: absolute;

    top: 96px;
    max-height: calc(100% - 96px - 96px);
    left: 25%;
    right: 25%;

    /*overflow: auto;*/
    /*-webkit-overflow-scrolling: touch;*/

    border-radius: 4px;
    outline: none;
    /*padding: 24px;*/
    /*background: #fafafa;*/
    box-shadow: 0 11px 15px -7px rgba(0,0,0,.2), 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12);

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;

    overflow: hidden;
    z-index: 100;
}

/*.ta-modal > form {*/
/*    flex: 1;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    flex-wrap: nowrap;*/
/*    justify-content: flex-start;*/
/*    align-content: stretch;*/
/*    align-items: stretch;*/
/*}*/


.ta-modal-header,
.ta-modal-footer {
     background: #fff;

     display: flex;
     flex-direction: row;
     flex-wrap: nowrap;
     justify-content: flex-start;
     align-content: flex-start;
     align-items: flex-start;

    padding: 16px 12px;

    height: 66px;
    min-height: 66px;
    overflow: hidden;

    box-sizing: border-box;
 }

.ta-modal-header {
    border-bottom: 2px solid #CFD8DC;
    order:-100;
    align-items: center;
}
.ta-modal-header,
.ta-modal-content:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.ta-modal-content {
    background: #ECEFF1;
}

.ta-modal-footer {
    border-top: 2px solid #CFD8DC;
    justify-content: space-between;
    order:100;
}
.ta-modal-footer,
.ta-modal-content:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.ta-modal-footer > *,
.ta-modal-header > * {
    margin: 0 4px;
}
.ta-modal-footer > div {
    flex: 1 1 100%
}

.ta-modal-content {
    flex: 1;
}
.ta-modal-content--scroll {
    overflow-x: visible;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.ta-modal-content--text,
.ta-modal-content__margin {
    padding: 16px;
}

.ta-modal-content--text {
    background: white;
}


.ta-modal-header__label {
    flex: 1;
    padding-right: 8px
}
.ta-modal-header__label > span {
    /*position: absolute;*/
    color: #616161;
    font-size: 18px;
    line-height: 32px;
    max-width: 100%;
    font-weight: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

@media (max-width: 768px)  {
    .ta-modal {
        top: 64px;
        max-height: calc(100% - 128px);
        left: 64px;
        right: 64px;
    }
}
@media (max-width: 425px)  {
    .ta-modal {
        top: 32px;
        max-height: calc(100% - 64px);
        left: 32px;
        right: 32px;
    }
}

.ta-modal-header > .ta-icon {

}

.ta-modal-header--warning {
    background: #ffecb3;
    border-bottom-color: #ffb300;
}
.ta-modal-header--warning > .ta-icon {
    color: #ffb300;
}
.ta-modal-header--error {
    background: #ffcdd2;
    border-bottom-color: #CC0000;
}
.ta-modal-header--error > .ta-icon {
    color: #CC0000;
}
.ta-modal-header--error > .ta-modal-header__label > span,
.ta-modal-header--warning > .ta-modal-header__label > span {
    color: #000000;
}
.ta-modal-header--error > button,
.ta-modal-header--warning > button {
    display: none;
}
.ta-modal-header--error + .ta-modal-content,
.ta-modal-header--warning + .ta-modal-content {
    background: white;
}
