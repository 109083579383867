.row {
    flex-wrap: wrap;
}
.details {
    display: block;
    margin: 0 8px 6px;
    /*padding: 6px 0;*/
    /*border-top: 1px solid rgba(207, 216, 220, 0.5);*/
    width: 100%;
    color: #b0bec5;
}
.details td:first-child {
    padding-right: 6px;
    vertical-align: top;
    white-space: nowrap;
    width: 1em;
}
/*.details td {*/
/*    padding-right: 6px;*/
/*    vertical-align: top;*/
/*}*/