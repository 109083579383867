.ag-react-container a,
.ag-react-container span {
  display: block;
}
.ag-react-container div,
.ag-react-container a,
.ag-react-container span,
.ag-react-container strong {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ag-layout-normal .ag-body-container {
  min-width: 100%!important;
}
.ag-theme-balham {
  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1em;
}
.ag-theme-balham .ag-row-even {
  background-color: #eff7ef;
}
.ag-theme-balham .ag-row-urgent {
  background-color: #f2dede;
}
.ag-theme-balham .ag-row-urgent.ag-row-hover,
.ag-theme-balham .ag-row-even.ag-row-hover {
  background-color: #ECF0F1;
}
.ag-theme-balham .ag-row-even.ag-row-selected {
  background-color: #b7e4ff;
}
.ag-theme-balham .ag-header-row {
  border-bottom: inherit;
}
.ag-pinned-right-header .ag-header-row,
.ag-pinned-right-header .ag-header-row{
  border-left: 1px solid #ccc;
}
.ag-pinned-right-cols-viewport .ag-row, .ag-pinned-right-header .ag-header-row, .ag-pinned-right-floating-bottom{
  box-shadow: inset 15px 0px 16px -21px rgba(0,0,0,0.90);
}
.ag-pinned-left-header .ag-header-row{
  border-right: 1px solid #ccc;
}
.ag-pinned-left-cols-viewport .ag-row, .ag-pinned-left-header .ag-header-row, .ag-pinned-left-floating-bottom{
  box-shadow: inset -15px 0px 16px -21px rgba(0,0,0,0.90);
}
.ag-theme-balham .ag-header-cell-resize::after{
  height: inherit;
  margin: 0;
}
.ag-theme-balham .ag-header-cell,
.ag-theme-balham .ag-header-group-cell,
.ag-theme-balham .ag-cell {
  padding-left: 6px;
  padding-right: 4px;
}
.ag-theme-balham .ag-cell.ag-text-right {
  text-align: right;
  padding-right: 7px;
}
.ag-theme-balham .ag-double-row .ag-cell{
  line-height: 40px;
}
.ag-theme-balham .ag-floating-bottom .ag-double-row .ag-cell{
  line-height: inherit;
}
.ag-theme-balham .ag-cell .detailed-cell {
  line-height: 1.35em;
  overflow: hidden;
  padding-top: 1px;
}

/*use this to add border to all cell in the list Table*/
/* .ag-theme-balham .ag-layout-normal .ag-cell{
  border-right:1px solid rgba(0, 0, 0, 0.05);
} */

.ag-theme-balham button.ag-paging-button{
  background-color: #fff;
  border-radius: 50%;
  width: 21px;
  height: 21px;
}
.ag-theme-balham .ag-paging-panel {
  background: linear-gradient(45deg, rgba(46,142,122,1), rgba(41,158,41,1));
  /* background-color: #cfd2d4; */
  border-top: 0;
  color: #ffffff;
}
.ag-theme-balham button:hover{
  opacity: 1;
  background-color: #fff;
}
.ag-theme-balham .ag-cell-no-focus:focus {
  outline: none;
}
.ag-theme-balham .ag-header{
  border-bottom: 1px solid #299e29;
  font-weight: 700;
  color: #333;
}
.ag-theme-balham .ag-filter-body{
  padding-right: inherit;
}
.ag-layout-normal .ag-floating-bottom{
  background: #f5f7f7;
  font-weight: 600;
  color: #228222;
  border-top: 1px solid #299e29;
}
.ag-floating-bottom{
  font-size: 1.15em;
}
#global-tabset-pane-SalesReport .ag-floating-bottom{
  font-size: 1em;
}
.ag-layout-normal .ag-floating-bottom .ag-react-container{
  /* font-size:0.85em; */
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ag-layout-normal .ag-floating-bottom .ag-floating-bottom-container{
  font-weight: normal;
  /* font-size: 1.1em; */
}
.ag-layout-normal .ag-floating-bottom .ag-row {
  background-color: inherit;
}
.cell-wrap-text {
  white-space: normal !important;
}
.deadline-in-row {
  display: flex;
}
.deadline-in-row > .deadline-in-row-sep {
  padding: 0 3px;
}
.ag-jobNumber{
  font-weight: 600;
}
.ag-theme-balham .ag-row-drag{
  width: 18px;
  margin-left: -4px;
  margin-top: 3px;
}
/*check this on Safari - to stop "daily schdule" page's left and middle column don't show scroll bar any more- does't it work?*/
.ag-pinned-left-cols-viewport, .ag-body-viewport-wrapper{
  overflow: hidden;
}
