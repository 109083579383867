.form-group .react-datepicker-wrapper,
.form-group .react-datepicker-wrapper .react-datepicker__input-container {
  display: block;
}

.react-datepicker-wrapper .form-control.has-error {
  border-color: #dd4b39;
}

.react-datepicker-wrapper .form-control.highlighted {
  font-size: 1.3em;
  font-weight: 400;
}

.form-control.is-today {
  background: #e7ffe9;
  color: #299e29;
}

.react-datepicker{
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.35);
  border: 1px solid #ccc;
  border-radius: 0;
  color: #666;
  font-size: 1.3rem;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
  border-radius: 2px;
  width: 3rem;
  line-height: 3rem;
  font-size: 0.85em;
  margin: 1px;
}
.react-datepicker__day--sun, .react-datepicker__day-name:first-child{
  color:#ff7878;
}
.react-datepicker__day--sat, .react-datepicker__day-name:last-child{
  color: #4797fa;
}
.react-datepicker__day-name, .react-datepicker__time-name{
  color: #666;
}
.react-datepicker__current-month, .react-datepicker-time__header{
  font-weight: 200;
  font-size: 1.1em;
}
.react-datepicker__header, .react-datepicker__today-button{
  border: 0;
}
.react-datepicker__header{
  padding-top: 5px;
  padding-bottom: 2px;
}
.react-datepicker__today-button{
  font-weight: 200;
  padding: 2px 0;
  color: #299e29;
  background: #fff;
  border-top: 1px solid #f0f0f0;
}
.react-datepicker__today-button:hover{
  color: #333;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover{
  background:#e0e0e0;
  color: #333;
  border-radius: 50%;
}
.react-datepicker__day--today{
  background-color: #299e29;
  color: #ffffff;
  border-radius: 50%;
}
.react-datepicker__day--today:hover{
  background-color: #1d711d;
  color: #ffffff;
}
.react-datepicker__day:hover{
  border-radius: 50%;
}
.react-datepicker__day--outside-month{
  opacity: 0.65;
}
.react-datepicker__day-name{
  line-height: inherit;
}
.react-datepicker__navigation{
  border-width: 0.65rem;
  top: 7px;
  outline: none;
}
.react-datepicker__navigation--previous{
  border-right-color: #797979;
}
.react-datepicker__navigation--next{
  border-left-color: #797979;
}
