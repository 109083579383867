.file-upload-modal .file-upload {
  height: 200px;
  background-size: 100px auto;
  background-image: url(../images/cloud-upload.svg);
  background-color: #d2d6de;
  background-position: center 35%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.file-upload-modal .file-upload p {
  margin-top: 100px;
  font-size: 18px;
  text-align: center;
  color: #999;
}

.file-upload-modal .file-upload:hover,
.file-upload-modal .file-upload.active {
  background-image: url(../images/cloud-upload-o.svg);
  background-color: #357CA5;
}

.file-upload-modal .file-upload:hover p,
.file-upload-modal .file-upload.active p {
  color: #fff;
}

.file-upload-modal .nav-stacked > li + li {
  margin-top: 0;
}

.file-upload-modal .nav-stacked > li {
  border-top: 1px solid #f4f4f4;
}

.file-upload-modal .nav-stacked > li > a {
  border-left: 0;
}
.file-upload-modal .nav-stacked > li > a.no-files-selected {
    text-align: center;
}

.file-upload-modal .nav-stacked > li > a:hover,
.file-upload-modal .nav-stacked > li > a:active,
.file-upload-modal .nav-stacked > li > a:focus {
  background: #fff;
  cursor: default;
}
