.ck,
.ta-ck--off {
    font-size: 14px;
    line-height: 20px;
    overflow-wrap: anywhere;
    font-family: source-han-sans-japanese, "ＭＳ Ｐゴシック", "MS PGothic", 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', system-ui, sans-serif;
}

.ck p,
.ck span,
.ta-ck--off p,
.ta-ck--off span {
    line-height: inherit !important;
    font-family: inherit !important;
    letter-spacing: inherit !important;
    font-size: 14px !important;
}

.ck .ta-font-size-medium {
    font-size: 14px !important;
    line-height: 18px !important;
}
.ck .ta-font-size-l {
    font-size: 18px !important;
    line-height: 24px !important;
}
.ck .ta-font-size-xl {
    font-size: 22px !important;
    line-height: 28px !important;
}
.ck .ta-font-size-xxl {
    font-size: 26px !important;
    line-height: 32px !important;
}

.ck .ta-bright-text {
    background: #424242 !important;
    padding: 0 0.25em !important;
    border-radius: 4px !important;
}

.ta-ck--sleep,
.ta-ck--sleep * {
    color: #b0bec5 !important;
}
.ck.ck-editor__editable {
    cursor: text;
}