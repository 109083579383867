.cellIcon {
    width: 48px;
    text-align: center;
    flex: 0 0 48px;
}
.cellMain {
    max-width: calc(100% - 96px);
    flex: 1;
}
.cellButton {
    width: 48px;
    flex: 0 0 48px;
}


.diff p {
    margin:0;
    word-break: break-all;
    white-space: break-spaces;
}

.details {
    display: block;
    margin-top: 4px;
    padding-top: 2px;
    border-top: 1px solid rgba(207, 216, 220, 0.5);
}
.details summary {
    color: #b0bec5;
    outline: none;
    display: block;
    list-style-type: none;
}

.details summary span::before {
    content: 'Show ';
}
.details[open] summary span::before {
    content: 'Hide ';
}
.details dl,
.details dt,
.details dd {
    margin:0;
    padding: 0;
}
.details dt {
    text-transform: capitalize;
}
