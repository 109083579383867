.quick-edit-box .col-xs-1,
.quick-edit-box .col-sm-1,
.quick-edit-box .col-md-1,
.quick-edit-box .col-lg-1,
.quick-edit-box .col-xs-2,
.quick-edit-box .col-sm-2,
.quick-edit-box .col-md-2,
.quick-edit-box .col-lg-2,
.quick-edit-box .col-xs-3,
.quick-edit-box .col-sm-3,
.quick-edit-box .col-md-3,
.quick-edit-box .col-lg-3,
.quick-edit-box .col-xs-4,
.quick-edit-box .col-sm-4,
.quick-edit-box .col-md-4,
.quick-edit-box .col-lg-4,
.quick-edit-box .col-xs-5,
.quick-edit-box .col-sm-5,
.quick-edit-box .col-md-5,
.quick-edit-box .col-lg-5,
.quick-edit-box .col-xs-6,
.quick-edit-box .col-sm-6,
.quick-edit-box .col-md-6,
.quick-edit-box .col-lg-6,
.quick-edit-box .col-xs-7,
.quick-edit-box .col-sm-7,
.quick-edit-box .col-md-7,
.quick-edit-box .col-lg-7,
.quick-edit-box .col-xs-8,
.quick-edit-box .col-sm-8,
.quick-edit-box .col-md-8,
.quick-edit-box .col-lg-8,
.quick-edit-box .col-xs-9,
.quick-edit-box .col-sm-9,
.quick-edit-box .col-md-9,
.quick-edit-box .col-lg-9,
.quick-edit-box .col-xs-10,
.quick-edit-box .col-sm-10,
.quick-edit-box .col-md-10,
.quick-edit-box .col-lg-10,
.quick-edit-box .col-xs-11,
.quick-edit-box .col-sm-11,
.quick-edit-box .col-md-11,
.quick-edit-box .col-lg-11,
.quick-edit-box .col-xs-12,
.quick-edit-box .col-sm-12,
.quick-edit-box .col-md-12,
.quick-edit-box .col-lg-12 {
    padding-right: 10px;
    padding-left: 10px;
}
.quick-edit-box .form-group{
  margin-bottom: 2px;
}
.quick-edit-box .col-lg-9 .form-group {
  margin-bottom: 3px;
}
.quick-edit-box .btn-sm /*, .btn-group-sm > .btn*/{
  padding: 1px 10px;
}
.form-horizontal .quick-edit-box .form-group-sm .control-label{
  font-size: 14px;
}
.quick-edit-box .checkbox{
  padding-top: 0;
}
.quick-edit-box .row{
  margin-right: -10px;
  margin-left: -5px;
}

.quick-edit-box .quick-edit-notification {
  padding: 0 10px;
}
.quick-edit-box .quick-edit-notification label {
  margin-bottom: 5px;
}
.quick-edit-box .quick-edit-notification .form-group {
  margin-left: 0;
  margin-right: 0;
}