.date-range-form-field {
  display: flex;
  align-items: center;
}

.date-range-form-field div:not(.date-range-form-field-sep) {
  flex-grow: 1;
}
.date-range-form-field .date-range-form-field-sep {
    margin: 0 6px;
    font-weight: 700;
}
