.page__tools {
    z-index: 100;
}

.toolSave {
    padding: 4px;
    margin-left: auto;
}

/*.columns *:after {*/
/*    !*bottom: 38px;*!*/
/*}*/

.listTool {
    position: relative;
    display: block;
    height: 48px;
    border-bottom: 1px solid #cfd8dc;
    background: #f5f6f7;
    border-radius: 4px 4px 0 0;
    flex: 0 0 48px;
}

.listTool__search {
    position: absolute;
    top: 8px;
    left: 8px;
    right: 176px;
}

.listTool__searchText {
    position: absolute !important;
    left: 0;
    right: 126px;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.listTool__searchTarget {
    position: absolute !important;
    right: 31px;
    width: 96px;
    border-radius: 0 !important;
}

.listTool__searchButton {
    position: absolute !important;
    right: 0;
    width: 32px;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.listTool__filter {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 160px;
}


.body {
    position: relative;
    flex: 1 1 100%;
    width: 100%;
}

.body__main {
    position: absolute;
    top: 16px;
    bottom: 16px;
    left: 16px;
    right: 392px;
    display: flex;
}

.body__side {
    position: absolute;
    top: 8px;
    bottom: 8px;
    right: 8px;
    width: 376px;
    display: flex;
    flex-direction: column;
}

.body__side > div {
    margin: 8px;
}

.listTable {
    /*position: absolute;*/
    /*top: 48px;*/
    /*bottom: 1px;*/
    /*left: 1px;*/
    /*right: 1px;*/
    flex: 1 1 100%;
}

.comments {
}

.rowMeta {
    display: block;
}

.rowLongText {
    word-break: break-all;
}

.shiftKey :global(.ta-table-body) {
    user-select: none;
}


/* test */

/*.toolFlex {*/
/*    flex: 1 1 auto;*/
/*    position: relative;*/
/*    !*padding: 4px;*!*/
/*    overflow: hidden;*/
/*    height: 40px;*/
/*    min-width: 420px;*/
/*    !*max-width: 600px;*!*/
/*}*/

/*.toolFlex > .toolFlex {*/
/*    min-width: auto*/
/*}*/

/*.toolSpace {*/
/*    !*background: #0d6aad;*!*/
/*    flex: 1 1 auto;*/
/*    position: relative;*/
/*    !*padding: 4px;*!*/
/*    overflow: hidden;*/
/*    height: 40px;*/
/*}*/

/*.search {*/
/*    flex: 1 1 auto;*/
/*}*/
/*.filter {*/
/*    width: 360px;*/
/*    flex: 0 1 360px;*/
/*}*/
