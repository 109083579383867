/* rgba(33, 150, 243, 0);  #2196f3 */

.ta-toolbar,
.ta-toolbar__group {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    margin:0;
    box-sizing: border-box;
    /*height: 56px;*/
    position: relative;
    z-index: 100;
}

.ta-toolbar {
    padding: 4px 12px;
    padding-top: 12px;
    margin-bottom: -8px;

    /*border-bottom: 2px solid #ced7db;*/
}

.ta-toolbar > .ta-toolbar__group,
.ta-toolbar > .ta-button,
.ta-toolbar > .ta-menu {
    margin: 4px;
}

.ta-toolbar--float-submit {
    flex-wrap: wrap;
    padding-right: 92px;
}
.ta-toolbar--float-submit > .ta-button--submit {
    position: absolute;
    top:4px;
    right:4px;
}

.ta-toolbar--wrap {
    flex-wrap: wrap;
}

.ta-toolbar--bg {
    border-radius: 4px;
    background: #f5f7f7;
}



.ta-toolbar--bg > * {
    border: 1px solid #BDC3C7;
    border-left-color: #d9dcde;
    border-right-color: #d9dcde;
    transition: all 0.3s;
    background: #f5f7f7;
    margin-right: -1px;
}
.ta-toolbar--bg > *:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-left-color: #BDC3C7;
}
.ta-toolbar--bg > *:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right-width: 1px;
    border-right-color: #BDC3C7;
}

.ta-toolbar__space,
.ta-toolbar__divider {
    margin: 4px;
    box-sizing: border-box;
    height:32px;
}
.ta-toolbar__divider {
    width: 1px;
    border-left: 1px solid #ced7db;
}
.ta-toolbar__space {
    width: 16px;
}

.ta-toolbar__group {
    flex: 1 1 auto;
}
.ta-toolbar__group > * {
    flex: 1 1 auto;
}

.ta-toolbar__item {
    box-sizing: border-box;
}
.ta-toolbar .ta-toolbar__item {
    padding: 4px;
    min-width: 40px;
    height: 40px;
}
.ta-toolbar--bg .ta-toolbar__item {
    padding: 7px;
    min-width: 48px;
    height: 48px;
}
.ta-toolbar__item > * {
    max-width: 100%;
}

.ta-toolbar-button {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;

    padding: 3px;
    min-width: 48px;
    height: 48px;

    box-sizing: border-box;

    /*border: 1px solid #BDC3C7;*/

    outline: none;
    /*border-radius: 4px;*/
    color: #222d32;
    cursor: pointer;
    user-select: none;

    position: relative;
}
.ta-toolbar-button__icon {
    width: 24px;
    height: 24px;
    margin: 8px;
    order: 0;
}
.ta-toolbar-button__label {
    font-size: 14px;
    line-height: 14px;
    height: 14px;
    margin: 8px 8px 8px 0;
    order: 1;
}

.ta-toolbar-button--icon-right .ta-toolbar-button__label {
    order: 0;
    margin: 8px 0 8px 8px;
}
.ta-toolbar-button--icon-right .ta-toolbar-button__icon {
    order: 1;
}



.ta-toolbar-button__tooltip {
    z-index: 1;
    position: absolute;
    bottom: -28px;
    white-space: nowrap;
    display: none;
    color: white;
    font-size: 12px;
    line-height: 12px;
    padding: 6px 8px;
    border-radius: 4px;
    background: #222d32;
}
.ta-toolbar-button:hover .ta-toolbar-button__tooltip {
    display: block;
}

.ta-toolbar-button:focus {
    box-shadow: 0 0 4px rgba(33, 150, 243, 1);
    border: 1px solid rgba(33, 150, 243, 1);
    z-index: 1;
}
.ta-toolbar-button:not(.ta-toolbar-button--disabled):hover {
    color: rgba(33, 150, 243, 1);
    /*border: 1px solid rgba(33, 150, 243, 1);*/
    background: rgba(33, 150, 243, 0.1);
    z-index: 1;
}

.ta-toolbar-button > span {
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    padding: 0 8px;
}

.ta-toolbar-button--disabled {
    color: #BDC3C7;
    cursor: default;
}

.ta-toolbar-button--primary:not(.ta-toolbar-button--disabled) {
    color: #ffffff;
    background: #2196f3;
    border: 1px solid #0069c0;
}
.ta-toolbar-button--primary:not(.ta-toolbar-button--disabled):hover {
    color: #ffffff;
    background: #0069c0;
}
