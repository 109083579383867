.expandCell {
    /*white-space: nowrap;*/
    /*width: 100%;*/
    /*overflow: hidden;*/
    /*text-overflow: ellipsis;*/
    /*!*border: 1px solid #000000;*!*/
    /*display: flex;*/
    /*flex-direction: column;*/
    /*position: absolute;*/
    /*top*/
    /*max-height: 100%;*/
    position: relative;

}
.expandCell:hover {
    /*overflow: visible;*/
    height: auto;
}
.expandCell > div {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}
.expandCell > div::after {
    position: relative;
    display: block;
    content: " ";
    background: white;
    z-index: 20;
    top: -18px;
    left: 20px;
    width: 20px;
    height: 20px;
    float: right;
    margin-top: 0px;
}

.expandCell > div > i {
    position: sticky;
    content: ' ';
    /*position: absolute;*/
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    /*height: 0;*/
    /*background: #1e282c;*/
    z-index: 1;
    display: block;
    text-align: right;
    font-weight: bold;
    height: 0;
}
.expandCell > div > i > span {
    display: block;
    width: 20px;
    height: 20px;
    /*border-radius: 50%;*/
    float: right;
    text-align: center;
    color: #333333;
    background: white;
    margin-top: -18px;
}
.expandCell:hover > div {
    position: static;
}
.expandCell:hover > div::after,
.expandCell:hover > div > i {
    display: none;
}

.rowSelected .expandCell > div::after,
.rowSelected .expandCell > div > i > span {
    background: #e3f2fd;
}
