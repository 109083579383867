.icons {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0 2px;
    user-select: none;
}

.iconText {
    font-size: 14px;
    line-height: 16px;
    width: 16px;
    height: 16px;
    text-align: center;
    font-weight: bold;
    color: #b0bec5;
}

.iconActive {
    color: #2196f3;
}
.segmentGroupLoading {
    /*min-height: 36px;*/
    /*display: flex;*/
    /*flex-direction: column;*/
    /*flex-wrap: nowrap;*/
    /*justify-content: flex-end;*/
    /*align-content: stretch;*/
    /*align-items: flex-start;*/
    display: block;
    /*border-top: 1px solid #cfd8dc;*/
    /*border-bottom: 1px solid #cfd8dc;*/
    background: #f5f6f7;
    position: relative;
    margin-bottom: 36px;
}
.segmentGroupLoading + .segmentGroupLoading {
    display: none;
}
.segmentGroupLoading:before,
.segmentGroupLoading:after {
    position: absolute;
    top: 0;
    padding: 8px;
    width: 48px;
    font-size: 14px;
    line-height: 20px;
    color: #b0bec5;
}
.segmentGroupLoading:before {
    content: '...';
    width: 48px;
    left: 0;
}
.segmentGroupLoading:after {
    content: 'Loading';
    left: 48px;

}
/*.segmentGroupReady + .segmentGroupLoading {*/
/*    justify-content: flex-start;*/
/*}*/

.focusTrap {
    position: absolute;
    top: -1px;
    left: -1px;
    padding: 0;
    margin: 0;
    height: 1px;
    width: 1px;
    border: none;
}

.hasHighlight > div:first-child,
.hasHighlight mark {
    background-color: #FDE68A;
}
.activeHighlight > div:first-child,
.hasHighlight mark.markFocus {
    background-color: #FBBF24;
}

.hasHighlight mark {
    background-color: #FDE68A !important;
    padding: 0;
    border-radius: 0!important;
}
.hasHighlight mark.markFocus {
    background-color: #FBBF24 !important;
}