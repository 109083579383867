/*****bof CheckboxGroupField******/
.row.welled {
  min-height: 20px;
  padding: 10px 0;
  margin-bottom: 0px;
  background-color: #f5f5f5;
  border: none;
  border-radius: 0px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
}

.checkbox.disabled label,
.has-error .checkbox.disabled label {
  /* text-decoration: line-through;
  text-decoration-color: #333; */
  color: #aaa;
}
/*****eof CheckboxGroupField******/
