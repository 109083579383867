.rc-time-picker {
  display: block;
}

.rc-time-picker-input {
  height: 24px;
  border-color: #d2d6de;
  outline: none;
  border-radius: 0;
  padding: 0 12px;
}

.has-error .rc-time-picker-input {
  border-color: #dd4b39;
}
