.tabs {
    z-index: 10;
    position: relative;
    border: 0;
    background: #f5f6f7;
    background-clip: border-box;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 1px solid #ced7db;
}

.tabs > header {
    background-clip: border-box;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.tabFilter {
    height: 48px;
}




.padding {
    padding: 6px;
}

.margin {
    margin: 6px;
}

.row,
.counter {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
}
.column {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
}
.half {
    flex: 0 1 50%;
    width: 50%;
    /*position: relative;*/
}
.suggestionsWrapper {
    flex: 0 1 50%;
    width: 50%;
    position: relative;
}
.suggestions {
    /*flex: 1 1 100%;*/
    display: block;
    position: absolute !important;

    /*position: absolute;*/
    top: 6px;
    left: 6px;
    right: 6px;
    bottom: 6px;
    height: auto !important;
}

.fill {
    display: flex;
    flex: 1 1 100%;
    width: 100%;
}

.hspace {
    display: flex;
    flex: 1 1 auto;
}

.counter {
    align-items: center;
}
.counter span {
    padding: 12px 6px;
    white-space: nowrap;
}