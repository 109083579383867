.ta-modal-steps,
.ta-modal-step {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    padding: 8px;
    overflow: hidden;
    box-sizing: border-box;
}

.ta-modal-steps {
    border-bottom: 2px solid #CFD8DC;
    background: #fff;
}

.ta-modal-step {
    flex: 0 1 auto;
}

.ta-modal-step div {
    line-height: 24px;
}

.ta-modal-step__no {
    color: #b0bec5;
    height: 24px;
    width: 24px;
    background: #ECEFF1;
    border-radius: 50%;
    text-align: center;
    margin-right: 8px;
}

.ta-modal-step .ta-modal-step__label {
    color: #b0bec5;
}

.ta-modal-step.is-active .ta-modal-step__no {
    color: #fff;
    background: #2196f3;
}

.ta-modal-step.is-done .ta-modal-step__label,
.ta-modal-step.is-active .ta-modal-step__label {
    color: #333;
}

.ta-modal-step.is-active .ta-modal-step__label {
    font-weight: bold;
}


