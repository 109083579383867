.ta-menu {
    position: relative;
}
.ta-menu > .ta-button--tile:after {
    content: 'keyboard_arrow_down';
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 18px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    display: block;
    color: #b0bec5;
    position: absolute;

    bottom: -2px;
    left:0;
    right: 0;
    z-index: 0;
}

.ta-menu__list {
    display: none;
    position: absolute;
    z-index: 1;
    min-width: 100%;
}
.ta-menu:hover .ta-menu__list,
.ta-menu__list:hover {
    display: block;
}

.ta-menu__list > div {
    display: block;
    background: white;
    border-radius: 4px;
    border: 1px solid rgb(144, 164, 174);
    box-shadow: 0 8px 12px 0 rgba(144, 164, 174,0.5), 0 2px 4px 0 rgba(144, 164, 174,0.5);
    margin-top: 4px;
}

.ta-menu__button {

}

.ta-menu-item {
    font-size: 14px;
    line-height: 24px;

    border-top: 1px solid #cfd8dc;
    margin: 0;
    list-style: none;
    padding: 3px 12px;

    white-space: nowrap;

    cursor: pointer;
}
.ta-menu-item:first-child {
    border-top: none;
}

.ta-menu-item:hover {
    color: #2196f3;
}

.ta-menu-item--disabled,
.ta-menu-item--disabled:hover {
    color: #b0bec5;
    cursor: default;
}