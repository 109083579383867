
.ta-color--light {
    color: #b0bec5;
}
.ta-color--active,
.ta-color--link {
    color: #2196f3;
}
.ta-color--warn {
    color: #fb8c00;
}
.ta-color--alert {
    color: #e53935;
}
.ta-color--confirm {
    color: #43a047
}


/* == Body Background == */
.content-wrapper {
    background-color: #eceff1;
}
/* == Global Tabs == */
.component-slider .nav-tabs li.active a,
.component-slider .nav-tabs li.active a:hover,
.component-slider .nav-tabs li.active a:focus {
    background-color: #eceff1;
}
