.ta-form-field {
    margin-bottom: 12px;
}

.ta-form-field__label {
    display: block;
}
.ta-form-field__label__required {
    color: #e53935;
    display: inline-block;
    margin-left: 0.25em;
}
.ta-form-field__caption,
.ta-form-field__error {
    margin-top: 6px;
}
.ta-form-field__error {
    color: #e53935;
}


