@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
.ta-input--text {
    font-weight: normal;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;

    /*border: 1px solid #DDDDDD;*/
    border: 1px solid #ced7db;

    transition: border 0.3s, box-shadow 0.3s;

    height: 32px;

    padding: 0 6px;
    background: white;

    border-radius: 4px;
    box-shadow: 0 0 0 rgba(33, 149, 242, 0);

}

.ta-input--text,
.ta-input--text * {
    box-sizing: border-box;
}

.ta-input--text.ta-input--focus {
    box-shadow: 0 0 5px rgba(33, 149, 242, 1) /* #03a9f4 */;
    border: 1px solid #03a9f4;
}
.ta-input--text.ta-input--invalid {
    border: 1px solid #e53935;
}
.ta-input--text.ta-input--invalid.ta-input--focus {
    box-shadow: 0 0 5px #e53935;
}

.ta-input--text .ta-input__button {
    color: #DDD;
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
    cursor: pointer;
}
.ta-input--text.ta-input--empty .ta-input__button {
    opacity: 0;
    cursor: default;
}


.ta-input--text .ta-input__button:hover {
    color: #333;
}

.ta-input--text input {
    outline: none;
    flex: 1 1;
    border: 0;
    padding: 0 6px;
    margin: 0;
    font-size: 14px;
    line-height: 18px;
    background: #FFFFFF !important;
    order: 2;
}

.ta-input--text .ta-input__icon:first-child {
    order:1
}
.ta-input--text input {
    order:2
}
.ta-input--text .ta-input__button {
    order:3
}
.ta-input--text .ta-input__button + .ta-input__icon {
    order:4
}

.ta-input--text .ta-input__icon:first-child + input {
    max-width: calc(100% - 28px);
}
.ta-input--text .ta-input__icon + .ta-input__button + input {
    max-width: calc(100% - 48px);
}
.ta-input--text .ta-input__icon + .ta-input__button + .ta-input__icon + input {
    max-width: calc(100% - 72px);
}

.ta-icon {
    font-size: 24px;
    line-height: 24px;
    width: 24px;
    height: 24px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    overflow: hidden;
    box-sizing: border-box;
    display: inline-block;
}
.ta-icon .material-icons {
    font-size: 24px;
}
.ta-icon--size-small {
    font-size: 0;
    line-height: 0;
    width: 16px;
    height: 16px;
}
.ta-icon--size-small .material-icons{
    font-size: 16px;
    /*width: 16px;*/
    /*height: 16px;*/
}
.ta-icon--active {
    color: #2196f3;
}

.ta-icon--color-light {
    color: #b0bec5;
}
.ta-icon--color-active {
    color: #2196f3;
}
.ta-icon--color-warn {
    color: #fb8c00;
}
.ta-icon--color-alert {
    color: #e53935;
}
.ta-icon--color-confirm {
    color: #43a047
}

/* rgba(33, 150, 243, 0);  #2196f3 */

.ta-toolbar,
.ta-toolbar__group {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    margin:0;
    box-sizing: border-box;
    /*height: 56px;*/
    position: relative;
    z-index: 100;
}

.ta-toolbar {
    padding: 4px 12px;
    padding-top: 12px;
    margin-bottom: -8px;

    /*border-bottom: 2px solid #ced7db;*/
}

.ta-toolbar > .ta-toolbar__group,
.ta-toolbar > .ta-button,
.ta-toolbar > .ta-menu {
    margin: 4px;
}

.ta-toolbar--float-submit {
    flex-wrap: wrap;
    padding-right: 92px;
}
.ta-toolbar--float-submit > .ta-button--submit {
    position: absolute;
    top:4px;
    right:4px;
}

.ta-toolbar--wrap {
    flex-wrap: wrap;
}

.ta-toolbar--bg {
    border-radius: 4px;
    background: #f5f7f7;
}



.ta-toolbar--bg > * {
    border: 1px solid #BDC3C7;
    border-left-color: #d9dcde;
    border-right-color: #d9dcde;
    transition: all 0.3s;
    background: #f5f7f7;
    margin-right: -1px;
}
.ta-toolbar--bg > *:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-left-color: #BDC3C7;
}
.ta-toolbar--bg > *:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right-width: 1px;
    border-right-color: #BDC3C7;
}

.ta-toolbar__space,
.ta-toolbar__divider {
    margin: 4px;
    box-sizing: border-box;
    height:32px;
}
.ta-toolbar__divider {
    width: 1px;
    border-left: 1px solid #ced7db;
}
.ta-toolbar__space {
    width: 16px;
}

.ta-toolbar__group {
    flex: 1 1 auto;
}
.ta-toolbar__group > * {
    flex: 1 1 auto;
}

.ta-toolbar__item {
    box-sizing: border-box;
}
.ta-toolbar .ta-toolbar__item {
    padding: 4px;
    min-width: 40px;
    height: 40px;
}
.ta-toolbar--bg .ta-toolbar__item {
    padding: 7px;
    min-width: 48px;
    height: 48px;
}
.ta-toolbar__item > * {
    max-width: 100%;
}

.ta-toolbar-button {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;

    padding: 3px;
    min-width: 48px;
    height: 48px;

    box-sizing: border-box;

    /*border: 1px solid #BDC3C7;*/

    outline: none;
    /*border-radius: 4px;*/
    color: #222d32;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    position: relative;
}
.ta-toolbar-button__icon {
    width: 24px;
    height: 24px;
    margin: 8px;
    order: 0;
}
.ta-toolbar-button__label {
    font-size: 14px;
    line-height: 14px;
    height: 14px;
    margin: 8px 8px 8px 0;
    order: 1;
}

.ta-toolbar-button--icon-right .ta-toolbar-button__label {
    order: 0;
    margin: 8px 0 8px 8px;
}
.ta-toolbar-button--icon-right .ta-toolbar-button__icon {
    order: 1;
}



.ta-toolbar-button__tooltip {
    z-index: 1;
    position: absolute;
    bottom: -28px;
    white-space: nowrap;
    display: none;
    color: white;
    font-size: 12px;
    line-height: 12px;
    padding: 6px 8px;
    border-radius: 4px;
    background: #222d32;
}
.ta-toolbar-button:hover .ta-toolbar-button__tooltip {
    display: block;
}

.ta-toolbar-button:focus {
    box-shadow: 0 0 4px rgba(33, 150, 243, 1);
    border: 1px solid rgba(33, 150, 243, 1);
    z-index: 1;
}
.ta-toolbar-button:not(.ta-toolbar-button--disabled):hover {
    color: rgba(33, 150, 243, 1);
    /*border: 1px solid rgba(33, 150, 243, 1);*/
    background: rgba(33, 150, 243, 0.1);
    z-index: 1;
}

.ta-toolbar-button > span {
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    padding: 0 8px;
}

.ta-toolbar-button--disabled {
    color: #BDC3C7;
    cursor: default;
}

.ta-toolbar-button--primary:not(.ta-toolbar-button--disabled) {
    color: #ffffff;
    background: #2196f3;
    border: 1px solid #0069c0;
}
.ta-toolbar-button--primary:not(.ta-toolbar-button--disabled):hover {
    color: #ffffff;
    background: #0069c0;
}

.ta-input--checkbox {
     position: relative;
     overflow: hidden;
     display: flex;
     flex-direction: row;
     flex-wrap: nowrap;
     justify-content: flex-start;
     align-content: center;
     align-items: center;
     padding: 3px;
     height: 32px;
 }

.ta-input--checkbox {
    /*border: 1px solid #ced7db;*/
    /*border-radius: 4px;*/
    /*background: white;*/
    /*background: rgba(206, 215, 219, 0.25);*/
}

.ta-input--checkbox input {
    outline: none;
    width: 1px;
    height: 1px;
    position: absolute;
    top: -1px;
    left: -1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    -webkit-appearance: none;
    -moz-appearance: none;
}

.ta-input--checkbox.ta-input--focus .ta-input__icon {
    color: #03a9f4;
}

.ta-input--checkbox input {
    order: 0;
}
.ta-input--checkbox .ta-input__icon {
    order: 1;
}
.ta-input--checkbox .ta-input__label {
    padding: 0 8px;
    order: 2;
}

.ta-input--checkbox.ta-input--align-right .ta-input__icon {
    order: 2;
}
.ta-input--checkbox.ta-input--align-right .ta-input__label {
    order: 1;
}

.ta-input--checkbox.ta-input--disabled .ta-input__icon,
.ta-input--checkbox.ta-input--disabled .ta-input__label {
    color: #b0bec5;
}


.ta-button {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;

    padding: 3px;
    min-height: 32px;
    box-sizing: border-box;

    background: rgba(206, 215, 219, 0.25);
    border: 1px solid #ced7db; /* #ced7db */

    outline: none;
    border-radius: 4px;
    color: #263238;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    transition: all 0.3s;

    position: relative;

    text-align: center;

}

.ta-button:focus {
    box-shadow: 0 0 4px rgba(33, 150, 243, 1);
    border: 1px solid rgba(33, 150, 243, 1);
    /*z-index: 1;*/
}

.ta-button:hover {
    color: #2196f3;
    border: 1px solid rgba(33, 150, 243, 1);
    background: rgba(33, 150, 243, 0.05);
    /*z-index: 1;*/
}

.ta-button--active {
    color: #2196f3;
}

.ta-button--light {
    color: #546e7a;
    background: transparent;
    border-color: transparent;
}

.ta-button:active,
.ta-button--primary,
.ta-button--submit {
    color: #ffffff;
    background: #2196f3;
    border: 1px solid #2196f3;
}

.ta-button--primary:hover,
.ta-button--submit:hover {
    color: #ffffff;
    border: 1px solid #0069c0;
    background: #0069c0;
}

.ta-button--disabled,
.ta-button--disabled:hover,
.ta-button--primary.ta-button--disabled,
.ta-button--primary.ta-button--disabled:hover,
.ta-button--submit.ta-button--disabled,
.ta-button--submit.ta-button--disabled:hover {
    color: #b0bec5;
    background: transparent;
    border-color: rgba(206, 215, 219, 0.5);
    cursor: default;
}

.ta-button--light.ta-button--disabled {
    border-color: transparent;
}

.ta-button__main {
    /*height: 24px;*/
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    font-weight: normal;
}


/*.ta-button__main > .ta-icon {*/
/*    font-size: 24px;*/
/*    width: 24px;*/
/*    height: 24px;*/
/*}*/

.ta-button__main > span {
    font-size: 14px;
    line-height: 14px;
    padding: 0 8px;
    white-space: nowrap;
}

.ta-button--icon-right .ta-button__main > .ta-icon {
    order: 2
}

.ta-button--icon-right .ta-button__main > span {
    order: 1
}

.ta-button--is-loading:not(.ta-button--icon-right) .ta-button__main > span:first-child {
    margin-left: 24px;
}


.ta-button__tooltip {
    z-index: 1;
    position: absolute;
    bottom: -28px;
    white-space: nowrap;
    display: none;
    color: white;
    font-size: 12px;
    line-height: 12px;
    padding: 6px 8px;
    border-radius: 4px;
    background: #222d32;
}

.ta-button:hover .ta-button__tooltip {
    display: block;
}

.ta-button--is-loading .ta-button__main > .ta-icon {
    opacity:0;
}
.ta-button--is-loading:after {
    content: ' ';
    position: absolute;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    top: 3px;
    left: 3px;
    box-sizing: border-box;
}

.ta-button--is-loading.ta-button--icon-right:after {
    left: auto;
    right: 3px;
}

.ta-button--is-loading:after {
    font-size: 0;
    line-height: 0;
    border: 4px solid rgba(38, 50, 56, 0.4);
    border-left-color: #263238;
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

.ta-button:active.ta-button--is-loading:after,
.ta-button--submit.ta-button--is-loading:after {
    border-color: rgba(255, 255, 255, 0.4);
    border-left-color: #fff;
}

/* Variations */

.ta-button--tile {
    width: 64px;
    height: 64px;
    min-width: 64px;
    min-height: 64px;
    max-width: 64px;
    max-height: 64px;
    padding: 11px 3px;
}
.ta-button--tile .ta-button__main {
    flex-direction: column;
}
.ta-button--tile .ta-button__main > span {
    font-size: 12px;
    line-height: 12px;
    padding: 4px 8px;
}
.ta-button--tile.ta-button--is-loading:after {
    top: 11px;
    left: 19px;
}



@-webkit-keyframes load8 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.ta-badge {
    position: absolute;
    z-index: 1;
    white-space: nowrap;
    display: block;
    color: white;
    font-size: 12px;
    line-height: 12px;
    font-weight: normal;
    text-align: center;
    padding: 6px;
    border-radius: 12px;
    background: red;
    height: 24px;
    min-width: 24px;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    box-sizing: border-box;
}
.ta-badge--top-right {
    top: -12px;
    right: -12px;
}
.ta-badge--top-left {
    top: -12px;
    left: -12px;
}
.ta-badge--center-left {
    top: calc(50% - 12px);
    left: -12px;
}

.ta-badge--small {
    font-size: 0;
    line-height: 0;
    padding: 0;
    border-radius: 6px;
    height: 12px;
    width: 12px;
    min-width: 12px;
}
.ta-badge--small.ta-badge--top-right {
    top: -6px;
    right: -6px;
}
.ta-badge--small.ta-badge--top-left {
    top: -6px;
    left: -6px;
}
.ta-badge--small.ta-badge--center-left {
    top: calc(50% - 6px);
    left: -6px;
}
.TaTooltip_tooltip__2Hsqu {
    z-index: -1000;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 4px;
    opacity: 1;
    /*transition: opacity 0.3s;*/
}

.TaTooltip_bubble__2ZIsL {
    white-space: nowrap;
    color: white;
    font-size: 12px;
    line-height: 12px;
    padding: 6px 8px;
    border-radius: 4px;
    background: #222d32;
    overflow: hidden;
    text-overflow: ellipsis;

}

.TaTooltip_arrowUp__3nguS {
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #222d32;
    margin: 0 auto;
    display: block;
}
.TaTooltip_arrowDown__auSU1 {
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #222d32;
}
.TaTooltip_arrowRight__-GFc3 {
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid #222d32;
}
.TaTooltip_arrowLeft__2rmxk {
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-right: 4px solid #222d32;
}

.ta-table-html {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%
}
.ta-table-html td {
    padding: 8px;
}

.ta-table {
    position: relative;
}

.ta-table,
.ta-table-header,
.ta-table-body,
.ta-table-footer,
.ta-table-row,
.ta-table-cell,
.ta-table-cellGroup {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
    box-sizing: border-box;
}

.ta-table,
.ta-table-header,
.ta-table-body,
.ta-table-footer {
    flex-direction: column;
    background-clip: border-box;
}

/*.ta-table {*/
/*    border: 1px solid rgb(144, 164, 174);*/
/*    border-radius: 4px;*/
/*    box-shadow: 0 3px 1px -2px rgba(144, 164, 174,.2), 0 2px 2px 0 rgba(144, 164, 174,.14), 0 1px 5px 0 rgba(144, 164, 174,.12);*/
/*}*/

.ta-table-html thead,
.ta-table-html tfoot,
.ta-table-header,
.ta-table-footer {
    background: #f5f6f7;
    position: relative;
}

.ta-table-html thead,
.ta-table-header {
    font-weight: bold;
    border-bottom: 1px solid #cfd8dc;
    z-index: 1;
}
.ta-table-header .ta-table-cell--light,
.ta-table-header .ta-table-cell--index {
    font-weight: normal;
}

.ta-table-body {
    align-content: flex-start;
    position: relative;
    z-index: 0;
    flex: 1 1;
}

.ta-table-body__rows {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 0;
    scroll-behavior: smooth;
}
.ta-table-body__rows--scroll-auto {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
}
.ta-table-body__rows--scroll-x {
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
}
.ta-table-body__rows--scroll-y {
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.ta-table-html tfoot,
.ta-table-footer {
    color: #b0bec5;
    border-top: 1px solid #cfd8dc;
    z-index: 2;
}

.ta-table-header--card {
    border-radius: 4px 4px 0 0;
}
.ta-table-footer--card {
    border-radius: 0 0 4px 4px;
}

.ta-table--flex,
.ta-table--flex .ta-table-body,
.ta-table-body--flex {
    flex: 1 1;
    /*max-height: 100%;*/
}

.ta-table--size-full {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
}



.ta-table-row {
    flex-direction: row;
}

.ta-table-cellGroup {
    flex: 1 1 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
    box-sizing: border-box;
    flex-direction: row;
}

.ta-table-header .ta-table-cell--border-top {
    border-top: 1px solid #cfd8dc;
}

.ta-table-html tbody tr,
.ta-table-body .ta-table-row {
    border-bottom: 1px solid #cfd8dc;
}

.ta-table-body .ta-table-row--selected {
    /*color: white;*/
    /*background: #3c8dbc;*/
    border-color: #42a5f5;
    background: #e3f2fd;
    /*box-shadow: inset -1px 0 0 1px #42a5f5, inset 1px 0 0 1px #42a5f5;*/
    /*box-shadow: inset 0 0 0 1px #42a5f5;*/
}

.ta-table-body .ta-table-row--selected .ta-table-cell {
    /*border-color: #bbdefb;*/
    border-color: rgba(66, 165, 245, 0.25);
}
.ta-table-body .ta-table-row--selected + .ta-table-row {
    border-top-color: #42a5f5;
}
.ta-table-body .ta-table-row--selected:last-child {
    border-color: #42a5f5;
}

.ta-table-cell {
    flex: 1 1 100%;
    color: #000000;
    flex-direction: row;
    cursor: default;
}

.ta-table--vertical-lines .ta-table-cell + .ta-table-cell,
.ta-table--vertical-lines .ta-table-cellGroup + .ta-table-cell,
.ta-table--vertical-lines .ta-table-cell + .ta-table-cellGroup {
    border-left: 1px solid #cfd8dc;
}
.ta-table-cell > div {
    display: block;
    box-sizing: border-box;
    position: relative;
    padding: 8px;
    width: 100%;
    outline: none;
    cursor: default;
}

.ta-table-cell.pb-0 > div {
    padding-bottom: 0;
}

.ta-table-header .ta-table-cell > div {
    padding: 0;
    margin: 8px;
    max-width: calc(100% - 16px);
}


.ta-table-cell--editable > div {
    cursor: text;
}
.ta-table-cell--editable > div:focus {
    /*border-radius: 4px;*/
    box-shadow: inset 0 0 0 2px #42a5f5;
}

.ta-table-cell--editable.ta-table-cell--error > div,
.ta-table-cell--editable.ta-table-cell--error > div:focus {
    box-shadow: inset 0 0 0 2px #CC0000;
}
.ta-table-cell--editable.ta-table-cell--error > div::after {
    content: 'Error';
    float:right;
    color: white;
    background: #CC0000;
    padding: 0 6px;
    border-radius: 4px;
}
.ta-table-cell--editable.ta-table-cell--error > div[data-error]::after {
    content: attr(data-error);
}

.ta-table-cell--light,
.ta-table-cell--index {
    color: #b0bec5;
    color: rgba(0,0,0,0.2);
}
.ta-table-row--selected .ta-table-cell--index {
    color: #42a5f5;
}
.ta-table-cell--index > div{
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.ta-table-cell--index.ta-table-cell--alert {
    color: red;
    font-weight: bold;
}
.ta-table-cell--right > div {
    text-align: right;
}
.ta-table-cell--center > div {
    text-align: center;
}
.ta-table-cell--valign-center {
    align-items:center;
}

.ta-table-cell > div > .ta-text--paragraph {
    margin-bottom: 0;
}

/* CK Editor */

.ta-table-cell > .ck-rounded-corners .ck.ck-editor__editable:not(.ck-editor__nested-editable),
.ta-table-cell > .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-rounded-corners {
    border-radius: 0;
}
.ta-table-cell > .ck {
    white-space: break-spaces !important;
}
.ta-table-cell > .ck.ck-focused {
    border-color: #42a5f5 !important;
    z-index: 4;
}
.ta-table-cell > .ck.ck-editor__editable_inline {
    padding: 0 7px !important;
}
.ta-table-cell > .ck.ta-ck--passive {
    padding: 1px 8px !important;
}
.ta-table-cell > .ck.ck-editor__editable_inline p,
.ta-table-cell > .ck.ta-ck--passive p {
    margin: 7px 0 !important;
}
.ta-table-cell > .ta-ck--off p {
    margin: 0 !important;
}

/* columns */

.ta-table-columns {
    height: 0;
    width: 100%;

    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
    box-sizing: border-box;
    flex-direction: row;

}
.ta-table-columns > div {
    flex: 1 1 100%;
}

.ta-table-column:after {
    content: ' ';
    position: absolute;
    border-left: 1px solid rgba(224, 224, 224, 0.4);
    margin-left: -1px;
    width: 0;
    top:0;
    bottom:0;
    z-index: 2;
}



/* Scroll */

.ta-table--scroll_xy {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
}
.ta-table--scroll_y {
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}
.ta-table--scroll_x {
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
}

/* Scroll - Header & Footer */

.ta-table--scroll_xy .ta-table-header,
.ta-table--scroll_y .ta-table-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    max-width: 100%;
}
.ta-table--scroll_xy .ta-table-footer,
.ta-table--scroll_y .ta-table-footer {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    right:0;
    left:0;
}

/* Look */
.ta-input-group {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;

    height: 32px;
    box-sizing: border-box;

    background: white;

    border-radius: 4px;
    box-shadow: inset 0 0 0 1px #ced7db;

}
.ta-input-group > .ta-icon {
    border-radius: 0;
    border: 1px solid #ced7db;
    padding: 4px;
    width: 32px;
    height: 32px;
}
.ta-input-group > .ta-input {
    border-radius: 0;
    flex: 1 1 auto;
}
.ta-input-group > .ta-input.ta-input-group__minWidth {
    flex: 0 0 auto;
}

.ta-input-group > .ta-icon + *,
.ta-input-group > .ta-input + * {
    margin-left: -1px;
}
.ta-input-group > *:first-child  {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.ta-input-group > *:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}





.ta-pane,
.ta-pane__padding {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
    flex-direction: column;
    max-height: 100%;
    box-sizing: border-box;
    max-width: 100%;
}
.ta-pane--row,
.ta-pane--row > .ta-pane__padding {
    flex-direction: row;
}
.ta-pane--column,
.ta-pane--column > .ta-pane__padding {
    flex-direction: column;
}
.ta-pane--page {
    position: fixed;
    top: 87px;
    left: 230px;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background-color: #eceff1;
}
body.sidebar-collapse .ta-pane--page {
    left: 50px;
}
@media (max-width: 767px)  {
    .ta-pane--page {
        left: 0;
    }
}
.ta-pane--flex {
    flex: 1 1;
    min-height: 0;
}

.ta-pane--scroll-y {
    overflow-x: visible;
    overflow-y: scroll;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

.ta-pane--full {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    /*z-index: 10000;*/
}
/*.ta-pane > .ta-pane {*/
/*    flex: 1 1 auto;*/
/*    align-self: auto;*/
/*}*/

.ta-pane--padding-half {
    padding: 8px;
}

.ta-pane__padding {
    margin: 16px;
    flex: 1 1;
    min-height: 0;
}
.ta-pane--flex.ta-pane--padding {
    flex: 1 0 auto;
}

.ta-pane > .ta-card {
    margin: 8px;
    max-height: calc(100% - 16px);
    max-width: calc(100% - 16px);
}

/*.ta-pane--row > .ta-card + .ta-card {*/
/*    margin: 0;*/
/*    margin-left: 24px;*/
/*}*/

/*.ta-pane__margin {*/
/*    padding: 24px;*/
/*    padding-top:8px;*/
/*}*/
/*.ta-pane__margin > * {*/
/*    margin: 8px;*/
/*}*/

.ta-card {
    background: white;
    border: 1px solid rgb(144, 164, 174);
    border-radius: 4px;
    box-shadow: 0 3px 1px -2px rgba(144, 164, 174,.2), 0 2px 2px 0 rgba(144, 164, 174,.14), 0 1px 5px 0 rgba(144, 164, 174,.12);
    max-height: 100%;
    overflow: hidden;
    background-clip: padding-box;
}

.ta-card,
.ta-card-header,
.ta-card-body,
.ta-card-footer {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
    box-sizing: border-box;
    flex-direction: column;
}

.ta-card-header,
.ta-card-footer {
    background: #f5f6f7;
}
.ta-card-header {
    font-weight: bold;
    margin-bottom: 0;
}
.ta-card-body {
    flex: 1 1;
    overflow: visible;
}
.ta-card-footer {
    color: #b0bec5;
}

.ta-card > .ta-card-header {
    border-bottom: 1px solid rgb(144, 164, 174);
    border-radius: 4px 4px 0 0;
}
.ta-card > .ta-card-footer {
    border-top: 1px solid rgb(144, 164, 174);
    border-radius: 0 0 4px 4px;
}

.ta-card--flex {
    flex: 1 1;
}

.ta-card--scroll,
.ta-card-scroll-y,
.ta-card--scroll > .ta-card-body,
.ta-card-scroll-y > .ta-card-body {
    overflow-x: visible;
    overflow-y: scroll;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    max-height: 100%;

}

/*

.ta-card > .ta-table,
.ta-card-body > .ta-table {
    border-radius: 4px;
}

.ta-card > .ta-table > .ta-table-header,
.ta-card-body > .ta-table > .ta-table-header {
    border-bottom: 1px solid rgb(144, 164, 174);
    border-radius: 4px 4px 0 0;
}
.ta-card > .ta-table > .ta-table-footer,
.ta-card-body > .ta-table > .ta-table-footer {
    border-top: 1px solid rgb(144, 164, 174);
    border-radius: 0 0 4px 4px;
}
 */

.ta-text--light {
    color: #b0bec5;
}
.ta-text--alert {
    color: #e53935;
}
.ta-text--warn {
    color: #fb8c00;
}
.ta-text--confirm {
    color: #43a047
}

.ta-menu {
    position: relative;
}
.ta-menu > .ta-button--tile:after {
    content: 'keyboard_arrow_down';
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 18px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    display: block;
    color: #b0bec5;
    position: absolute;

    bottom: -2px;
    left:0;
    right: 0;
    z-index: 0;
}

.ta-menu__list {
    display: none;
    position: absolute;
    z-index: 1;
    min-width: 100%;
}
.ta-menu:hover .ta-menu__list,
.ta-menu__list:hover {
    display: block;
}

.ta-menu__list > div {
    display: block;
    background: white;
    border-radius: 4px;
    border: 1px solid rgb(144, 164, 174);
    box-shadow: 0 8px 12px 0 rgba(144, 164, 174,0.5), 0 2px 4px 0 rgba(144, 164, 174,0.5);
    margin-top: 4px;
}

.ta-menu__button {

}

.ta-menu-item {
    font-size: 14px;
    line-height: 24px;

    border-top: 1px solid #cfd8dc;
    margin: 0;
    list-style: none;
    padding: 3px 12px;

    white-space: nowrap;

    cursor: pointer;
}
.ta-menu-item:first-child {
    border-top: none;
}

.ta-menu-item:hover {
    color: #2196f3;
}

.ta-menu-item--disabled,
.ta-menu-item--disabled:hover {
    color: #b0bec5;
    cursor: default;
}
.ReactModal__Body--open {
    position: absolute;
    top: 0;
    left: 0;
    right:0;
    bottom:0;
    height: 100%;
    height: 100vh;
    overflow: hidden;
}


.ta-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.32);
    z-index: 999;
}

.ta-modal {
    position: absolute;

    top: 96px;
    max-height: calc(100% - 96px - 96px);
    left: 25%;
    right: 25%;

    /*overflow: auto;*/
    /*-webkit-overflow-scrolling: touch;*/

    border-radius: 4px;
    outline: none;
    /*padding: 24px;*/
    /*background: #fafafa;*/
    box-shadow: 0 11px 15px -7px rgba(0,0,0,.2), 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12);

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;

    overflow: hidden;
    z-index: 100;
}

/*.ta-modal > form {*/
/*    flex: 1;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    flex-wrap: nowrap;*/
/*    justify-content: flex-start;*/
/*    align-content: stretch;*/
/*    align-items: stretch;*/
/*}*/


.ta-modal-header,
.ta-modal-footer {
     background: #fff;

     display: flex;
     flex-direction: row;
     flex-wrap: nowrap;
     justify-content: flex-start;
     align-content: flex-start;
     align-items: flex-start;

    padding: 16px 12px;

    height: 66px;
    min-height: 66px;
    overflow: hidden;

    box-sizing: border-box;
 }

.ta-modal-header {
    border-bottom: 2px solid #CFD8DC;
    order:-100;
    align-items: center;
}
.ta-modal-header,
.ta-modal-content:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.ta-modal-content {
    background: #ECEFF1;
}

.ta-modal-footer {
    border-top: 2px solid #CFD8DC;
    justify-content: space-between;
    order:100;
}
.ta-modal-footer,
.ta-modal-content:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.ta-modal-footer > *,
.ta-modal-header > * {
    margin: 0 4px;
}
.ta-modal-footer > div {
    flex: 1 1 100%
}

.ta-modal-content {
    flex: 1 1;
}
.ta-modal-content--scroll {
    overflow-x: visible;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.ta-modal-content--text,
.ta-modal-content__margin {
    padding: 16px;
}

.ta-modal-content--text {
    background: white;
}


.ta-modal-header__label {
    flex: 1 1;
    padding-right: 8px
}
.ta-modal-header__label > span {
    /*position: absolute;*/
    color: #616161;
    font-size: 18px;
    line-height: 32px;
    max-width: 100%;
    font-weight: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

@media (max-width: 768px)  {
    .ta-modal {
        top: 64px;
        max-height: calc(100% - 128px);
        left: 64px;
        right: 64px;
    }
}
@media (max-width: 425px)  {
    .ta-modal {
        top: 32px;
        max-height: calc(100% - 64px);
        left: 32px;
        right: 32px;
    }
}

.ta-modal-header > .ta-icon {

}

.ta-modal-header--warning {
    background: #ffecb3;
    border-bottom-color: #ffb300;
}
.ta-modal-header--warning > .ta-icon {
    color: #ffb300;
}
.ta-modal-header--error {
    background: #ffcdd2;
    border-bottom-color: #CC0000;
}
.ta-modal-header--error > .ta-icon {
    color: #CC0000;
}
.ta-modal-header--error > .ta-modal-header__label > span,
.ta-modal-header--warning > .ta-modal-header__label > span {
    color: #000000;
}
.ta-modal-header--error > button,
.ta-modal-header--warning > button {
    display: none;
}
.ta-modal-header--error + .ta-modal-content,
.ta-modal-header--warning + .ta-modal-content {
    background: white;
}

.ta-form-field {
    margin-bottom: 12px;
}

.ta-form-field__label {
    display: block;
}
.ta-form-field__label__required {
    color: #e53935;
    display: inline-block;
    margin-left: 0.25em;
}
.ta-form-field__caption,
.ta-form-field__error {
    margin-top: 6px;
}
.ta-form-field__error {
    color: #e53935;
}



.TranslationMemorySearch_expandSearchCell__1RM5D {
  overflow: hidden;
  white-space: nowrap;
  width: 220px;
  text-overflow: ellipsis;
}

.TranslationMemorySearch_expandSearchCell__1RM5D:hover {
  overflow: visible;
  white-space: normal;
}
.translationMemoryDetail_expandCell__2LkYV {
    /*white-space: nowrap;*/
    /*width: 100%;*/
    /*overflow: hidden;*/
    /*text-overflow: ellipsis;*/
    /*!*border: 1px solid #000000;*!*/
    /*display: flex;*/
    /*flex-direction: column;*/
    /*position: absolute;*/
    /*top*/
    /*max-height: 100%;*/
    position: relative;

}
.translationMemoryDetail_expandCell__2LkYV:hover {
    /*overflow: visible;*/
    height: auto;
}
.translationMemoryDetail_expandCell__2LkYV > div {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}
.translationMemoryDetail_expandCell__2LkYV > div::after {
    position: relative;
    display: block;
    content: " ";
    background: white;
    z-index: 20;
    top: -18px;
    left: 20px;
    width: 20px;
    height: 20px;
    float: right;
    margin-top: 0px;
}

.translationMemoryDetail_expandCell__2LkYV > div > i {
    position: -webkit-sticky;
    position: sticky;
    content: ' ';
    /*position: absolute;*/
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    /*height: 0;*/
    /*background: #1e282c;*/
    z-index: 1;
    display: block;
    text-align: right;
    font-weight: bold;
    height: 0;
}
.translationMemoryDetail_expandCell__2LkYV > div > i > span {
    display: block;
    width: 20px;
    height: 20px;
    /*border-radius: 50%;*/
    float: right;
    text-align: center;
    color: #333333;
    background: white;
    margin-top: -18px;
}
.translationMemoryDetail_expandCell__2LkYV:hover > div {
    position: static;
}
.translationMemoryDetail_expandCell__2LkYV:hover > div::after,
.translationMemoryDetail_expandCell__2LkYV:hover > div > i {
    display: none;
}

.translationMemoryDetail_rowSelected__1hcLc .translationMemoryDetail_expandCell__2LkYV > div::after,
.translationMemoryDetail_rowSelected__1hcLc .translationMemoryDetail_expandCell__2LkYV > div > i > span {
    background: #e3f2fd;
}

.ta-modal-steps,
.ta-modal-step {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    padding: 8px;
    overflow: hidden;
    box-sizing: border-box;
}

.ta-modal-steps {
    border-bottom: 2px solid #CFD8DC;
    background: #fff;
}

.ta-modal-step {
    flex: 0 1 auto;
}

.ta-modal-step div {
    line-height: 24px;
}

.ta-modal-step__no {
    color: #b0bec5;
    height: 24px;
    width: 24px;
    background: #ECEFF1;
    border-radius: 50%;
    text-align: center;
    margin-right: 8px;
}

.ta-modal-step .ta-modal-step__label {
    color: #b0bec5;
}

.ta-modal-step.is-active .ta-modal-step__no {
    color: #fff;
    background: #2196f3;
}

.ta-modal-step.is-done .ta-modal-step__label,
.ta-modal-step.is-active .ta-modal-step__label {
    color: #333;
}

.ta-modal-step.is-active .ta-modal-step__label {
    font-weight: bold;
}



.ta-tab,
.ta-tab-group {
    overflow: visible;
    position: relative;
}

.ta-tab-group {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
    box-sizing: border-box;
    flex-direction: column;
}

.ta-tab-group--flex {
    flex: 1 0 100%;
    max-height: 100%;
}

.ta-tab-scroll {
    overflow-x: visible;
    overflow-y: scroll;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

.ta-tab-group__head {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: center;
    background: transparent;
    border-bottom: 1px solid #ced7db;
    height: 48px;
}

.ta-tab-group--flex > .ta-tab-group__head + .ta-tab {
    flex: 1 1;
    max-height: calc(100% - 32px);
}

.ta-tab-label {

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-weight: normal;

    padding: 3px;
    padding-bottom: 0;
    height: 48px;

    box-sizing: border-box;

    background: transparent;
    border: 0;
    border-bottom: 3px solid rgba(33, 150, 243, 0);
    border-bottom: 3px solid rgba(33, 150, 243, 0);

    outline: none;
    color: #263238;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    transition: all 0.3s;

    position: relative;

    text-align: center;


}

.ta-tab-label {

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-weight: normal;

    padding: 4px;
    /*padding-bottom: 0;*/
    height: 100%;

    box-sizing: border-box;

    background: transparent;
    border: 0;
    /*border-bottom: 3px solid rgba(33, 150, 243, 0);*/
    /*border-bottom: 3px solid rgba(33, 150, 243, 0);*/

    outline: none;
    color: #263238;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    transition: all 0.3s;

    position: relative;

    text-align: center;
}
.ta-tab-label--disabled {
    color: #b0bec5;
}

.ta-tab-label > span {
    font-size: 14px;
    line-height: 14px;
    padding: 0 8px;
}

.ta-tab-label > .ta-icon {
    order: 2
}

.ta-tab-label > span {
    order: 1
}


.ta-tab-label:not(.ta-tab-label--disabled):hover,
.ta-tab-label--active {
    color: rgba(33, 150, 243, 1);
}

/*
.ta-tab-label:hover {
    color: rgba(33, 150, 243, 1);
}

.ta-tab-label--active {
    color: rgba(33, 150, 243, 1);
    border-color: #2196f3;
}
*/

.ta-tab-label:after {
    display: none;
    position: absolute;
    bottom: -1px;
    left: 12px;
    right: 12px;
    height: 4px;
    content: '';
    background-color: rgba(33, 150, 243, 1);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    z-index: 1;
}
.ta-tab-label--active:after {
    display: block;
}

.ta-tab-group--compact .ta-tab-group__head {
    height: 37px;
}

.ta-tab-group--grey .ta-tab-group__head {
    background: #f5f6f7;
}

.ta-form-group {
    margin: 16px;
}
.TaMhtEditor_page__tools__3_HBa {
    z-index: 100;
}

.TaMhtEditor_toolSave__eV5Wd {
    padding: 4px;
    margin-left: auto;
}

/*.columns *:after {*/
/*    !*bottom: 38px;*!*/
/*}*/

.TaMhtEditor_listTool__3QyCJ {
    position: relative;
    display: block;
    height: 48px;
    border-bottom: 1px solid #cfd8dc;
    background: #f5f6f7;
    border-radius: 4px 4px 0 0;
    flex: 0 0 48px;
}

.TaMhtEditor_listTool__search__37eYN {
    position: absolute;
    top: 8px;
    left: 8px;
    right: 176px;
}

.TaMhtEditor_listTool__searchText__2CAIO {
    position: absolute !important;
    left: 0;
    right: 126px;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.TaMhtEditor_listTool__searchTarget__2PKH4 {
    position: absolute !important;
    right: 31px;
    width: 96px;
    border-radius: 0 !important;
}

.TaMhtEditor_listTool__searchButton__sQD0I {
    position: absolute !important;
    right: 0;
    width: 32px;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.TaMhtEditor_listTool__filter__1p9Vb {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 160px;
}


.TaMhtEditor_body__3s6zC {
    position: relative;
    flex: 1 1 100%;
    width: 100%;
}

.TaMhtEditor_body__main__2xktG {
    position: absolute;
    top: 16px;
    bottom: 16px;
    left: 16px;
    right: 392px;
    display: flex;
}

.TaMhtEditor_body__side__275Bo {
    position: absolute;
    top: 8px;
    bottom: 8px;
    right: 8px;
    width: 376px;
    display: flex;
    flex-direction: column;
}

.TaMhtEditor_body__side__275Bo > div {
    margin: 8px;
}

.TaMhtEditor_listTable__HVAOI {
    /*position: absolute;*/
    /*top: 48px;*/
    /*bottom: 1px;*/
    /*left: 1px;*/
    /*right: 1px;*/
    flex: 1 1 100%;
}

.TaMhtEditor_comments__1Wswp {
}

.TaMhtEditor_rowMeta__1aG0t {
    display: block;
}

.TaMhtEditor_rowLongText__3nwFJ {
    word-break: break-all;
}

.TaMhtEditor_shiftKey__LnfJI .ta-table-body {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}


/* test */

/*.toolFlex {*/
/*    flex: 1 1 auto;*/
/*    position: relative;*/
/*    !*padding: 4px;*!*/
/*    overflow: hidden;*/
/*    height: 40px;*/
/*    min-width: 420px;*/
/*    !*max-width: 600px;*!*/
/*}*/

/*.toolFlex > .toolFlex {*/
/*    min-width: auto*/
/*}*/

/*.toolSpace {*/
/*    !*background: #0d6aad;*!*/
/*    flex: 1 1 auto;*/
/*    position: relative;*/
/*    !*padding: 4px;*!*/
/*    overflow: hidden;*/
/*    height: 40px;*/
/*}*/

/*.search {*/
/*    flex: 1 1 auto;*/
/*}*/
/*.filter {*/
/*    width: 360px;*/
/*    flex: 0 1 360px;*/
/*}*/

.TaMhtEditorTerms_row___B_If {
    flex-wrap: wrap;
}
.TaMhtEditorTerms_details__2g6QW {
    display: block;
    margin: 0 8px 6px;
    /*padding: 6px 0;*/
    /*border-top: 1px solid rgba(207, 216, 220, 0.5);*/
    width: 100%;
    color: #b0bec5;
}
.TaMhtEditorTerms_details__2g6QW td:first-child {
    padding-right: 6px;
    vertical-align: top;
    white-space: nowrap;
    width: 1em;
}
/*.details td {*/
/*    padding-right: 6px;*/
/*    vertical-align: top;*/
/*}*/
.ta-input {
    font-size: 0;
    line-height: 0;
    -webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;      /* IE 10+ */
    user-select: none;          /* Likely future */
    position: relative;
    margin: 0;
    color: #222d32;
}
.ta-input--focus {
    z-index: 1;
}

.ta-input__icon {
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
    text-align: center;
    overflow: hidden;
    transition: color 0.3s;
}
.ta-input__button {
    font-size: 0;
    line-height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    background: transparent;
    outline: none;
}
.ta-input__label {
    font-size: 14px;
    line-height: 14px;
    font-weight: normal;
    color: #222d32;
    white-space: nowrap;
}

.ta-input input:-ms-input-placeholder {
    color: #DDDDDD !important;
}

.ta-input input::-ms-input-placeholder {
    color: #DDDDDD !important;
}

.ta-input input:-ms-input-placeholder,
.ta-input input::-webkit-input-placeholder,
.ta-input input::placeholder {
    color: #DDDDDD !important;
}

.ta-input--active .ta-input__icon {
    color: #2196f3;
}

.ta-input--textarea {
    font-weight: normal;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;

    /*border: 1px solid #DDDDDD;*/
    border: 1px solid #ced7db;

    transition: border 0.3s, box-shadow 0.3s;

    min-height: 32px;
    box-sizing: border-box;

    padding: 0;
    background: white;

    border-radius: 4px;
    box-shadow: 0 0 0 rgba(33, 149, 242, 0);
    overflow: hidden;
}
.ta-input--textarea.ta-input--focus {
    box-shadow: 0 0 5px rgba(33, 149, 242, 1) /* #03a9f4 */;
    border: 1px solid #03a9f4;
}
.ta-input--textarea.ta-input--invalid {
    border: 1px solid red;
}
.ta-input--textarea.ta-input--invalid.ta-input--focus {
    box-shadow: 0 0 5px red;
}
.ta-input--textarea textarea {
    outline: none;
    flex: 1 1;
    border: 0;
    padding: 6px 12px;
    margin: 0;
    font-size: 14px;
    line-height: 18px;
    background: #FFFFFF !important;

    min-height: 32px;
}
.TaMhtEditorMemories_cellIcon__3Wn-t {
    width: 48px;
    text-align: center;
    flex: 0 0 48px;
}
.TaMhtEditorMemories_cellMain__yWdps {
    max-width: calc(100% - 96px);
    flex: 1 1;
}
.TaMhtEditorMemories_cellButton__pBuQN {
    width: 48px;
    flex: 0 0 48px;
}


.TaMhtEditorMemories_diff__Q_fIe p {
    margin:0;
    word-break: break-all;
    white-space: break-spaces;
}

.TaMhtEditorMemories_details__cbbxf {
    display: block;
    margin-top: 4px;
    padding-top: 2px;
    border-top: 1px solid rgba(207, 216, 220, 0.5);
}
.TaMhtEditorMemories_details__cbbxf summary {
    color: #b0bec5;
    outline: none;
    display: block;
    list-style-type: none;
}

.TaMhtEditorMemories_details__cbbxf summary span::before {
    content: 'Show ';
}
.TaMhtEditorMemories_details__cbbxf[open] summary span::before {
    content: 'Hide ';
}
.TaMhtEditorMemories_details__cbbxf dl,
.TaMhtEditorMemories_details__cbbxf dt,
.TaMhtEditorMemories_details__cbbxf dd {
    margin:0;
    padding: 0;
}
.TaMhtEditorMemories_details__cbbxf dt {
    text-transform: capitalize;
}

.TaMhtEditorRevisions_text__1rMWO p {
    margin:0;
    word-break: break-all;
    white-space: break-spaces;
}

.ta-input-dropzone {
    height: 200px;
    background-size: 100px auto;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg version='1.1' viewBox='0 0 106 80' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(2 2)'%3E%3Cpath id='a' d='m70.2 65.7h11v-0.1c11.7-0.8 20.8-10.4 20.8-22.3 0-10.3-7-19-16.5-21.5-2.4-12.2-13.2-21.5-26.2-21.5-10.1 0-18.8 5.6-23.4 13.8-1.6-0.5-3.3-0.7-5-0.7-9 0-16.5 6.7-17.7 15.4-7.7 2.4-13.2 9.5-13.2 18 0 10.4 8.5 18.9 18.9 18.9h13.2' stroke='%2390A4AE' stroke-width='3'/%3E%3Cg transform='translate(40 28)'%3E%3Cpath d='m12.2 3.6v45.5' stroke='%2390A4AE' stroke-width='3'/%3E%3Cpolygon points='0 13.5 2.2 15.6 12.2 4.7 22.3 15.6 24.5 13.5 12.2 0.3' fill='%2390A4AE' fill-rule='nonzero'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    background-color: #CFD8DC;
    background-position: center 35%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    color: #90A4AE;

}

.ta-input-dropzone__placeholder,
.ta-input-dropzone .file-upload .text-center
{
    margin-top: 100px;
    font-size: 18px;
    text-align: center;
}

.ta-input-dropzone:hover {
    color: #FFF;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg version='1.1' viewBox='0 0 106 80' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(2 2)'%3E%3Cpath id='a' d='m70.2 65.7h11v-0.1c11.7-0.8 20.8-10.4 20.8-22.3 0-10.3-7-19-16.5-21.5-2.4-12.2-13.2-21.5-26.2-21.5-10.1 0-18.8 5.6-23.4 13.8-1.6-0.5-3.3-0.7-5-0.7-9 0-16.5 6.7-17.7 15.4-7.7 2.4-13.2 9.5-13.2 18 0 10.4 8.5 18.9 18.9 18.9h13.2' stroke='%23FFF' stroke-width='3'/%3E%3Cg transform='translate(40 28)'%3E%3Cpath d='m12.2 3.6v45.5' stroke='%23FFF' stroke-width='3'/%3E%3Cpolygon points='0 13.5 2.2 15.6 12.2 4.7 22.3 15.6 24.5 13.5 12.2 0.3' fill='%23FFF' fill-rule='nonzero'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    background-color: #357CA5;
}


.ta-form-messages,
.ta-form-message {
    list-style: none;
    margin: 0;
    padding:0;
}
.ta-form-messages {
    font-size: 0;
    line-height: 0;
}
.ta-form-message {
    font-size: 14px;
    line-height: 14px;
    padding: 8px 16px;
}
.ta-form-message--error {
    color: white;
    background: #e53935;
}


.ck,
.ta-ck--off {
    font-size: 14px;
    line-height: 20px;
    overflow-wrap: anywhere;
    font-family: source-han-sans-japanese, "ＭＳ Ｐゴシック", "MS PGothic", 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', system-ui, sans-serif;
}

.ck p,
.ck span,
.ta-ck--off p,
.ta-ck--off span {
    line-height: inherit !important;
    font-family: inherit !important;
    letter-spacing: inherit !important;
    font-size: 14px !important;
}

.ck .ta-font-size-medium {
    font-size: 14px !important;
    line-height: 18px !important;
}
.ck .ta-font-size-l {
    font-size: 18px !important;
    line-height: 24px !important;
}
.ck .ta-font-size-xl {
    font-size: 22px !important;
    line-height: 28px !important;
}
.ck .ta-font-size-xxl {
    font-size: 26px !important;
    line-height: 32px !important;
}

.ck .ta-bright-text {
    background: #424242 !important;
    padding: 0 0.25em !important;
    border-radius: 4px !important;
}

.ta-ck--sleep,
.ta-ck--sleep * {
    color: #b0bec5 !important;
}
.ck.ck-editor__editable {
    cursor: text;
}
.TaMhtEditorSegment_icons__1fhiC {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0 2px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.TaMhtEditorSegment_iconText__fe2d- {
    font-size: 14px;
    line-height: 16px;
    width: 16px;
    height: 16px;
    text-align: center;
    font-weight: bold;
    color: #b0bec5;
}

.TaMhtEditorSegment_iconActive__FGney {
    color: #2196f3;
}
.TaMhtEditorSegment_segmentGroupLoading__1hft9 {
    /*min-height: 36px;*/
    /*display: flex;*/
    /*flex-direction: column;*/
    /*flex-wrap: nowrap;*/
    /*justify-content: flex-end;*/
    /*align-content: stretch;*/
    /*align-items: flex-start;*/
    display: block;
    /*border-top: 1px solid #cfd8dc;*/
    /*border-bottom: 1px solid #cfd8dc;*/
    background: #f5f6f7;
    position: relative;
    margin-bottom: 36px;
}
.TaMhtEditorSegment_segmentGroupLoading__1hft9 + .TaMhtEditorSegment_segmentGroupLoading__1hft9 {
    display: none;
}
.TaMhtEditorSegment_segmentGroupLoading__1hft9:before,
.TaMhtEditorSegment_segmentGroupLoading__1hft9:after {
    position: absolute;
    top: 0;
    padding: 8px;
    width: 48px;
    font-size: 14px;
    line-height: 20px;
    color: #b0bec5;
}
.TaMhtEditorSegment_segmentGroupLoading__1hft9:before {
    content: '...';
    width: 48px;
    left: 0;
}
.TaMhtEditorSegment_segmentGroupLoading__1hft9:after {
    content: 'Loading';
    left: 48px;

}
/*.segmentGroupReady + .segmentGroupLoading {*/
/*    justify-content: flex-start;*/
/*}*/

.TaMhtEditorSegment_focusTrap__37rmf {
    position: absolute;
    top: -1px;
    left: -1px;
    padding: 0;
    margin: 0;
    height: 1px;
    width: 1px;
    border: none;
}

.TaMhtEditorSegment_hasHighlight__3GPSK > div:first-child,
.TaMhtEditorSegment_hasHighlight__3GPSK mark {
    background-color: #FDE68A;
}
.TaMhtEditorSegment_activeHighlight__3D60V > div:first-child,
.TaMhtEditorSegment_hasHighlight__3GPSK mark.TaMhtEditorSegment_markFocus__i9zg6 {
    background-color: #FBBF24;
}

.TaMhtEditorSegment_hasHighlight__3GPSK mark {
    background-color: #FDE68A !important;
    padding: 0;
    border-radius: 0!important;
}
.TaMhtEditorSegment_hasHighlight__3GPSK mark.TaMhtEditorSegment_markFocus__i9zg6 {
    background-color: #FBBF24 !important;
}
.TaMhtEditorWidget_tabs__3PL15 {
    z-index: 10;
    position: relative;
    border: 0;
    background: #f5f6f7;
    background-clip: border-box;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 1px solid #ced7db;
}

.TaMhtEditorWidget_tabs__3PL15 > header {
    background-clip: border-box;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.TaMhtEditorWidget_tabFilter__Yw0Qw {
    height: 48px;
}




.TaMhtEditorWidget_padding__Ru7cv {
    padding: 6px;
}

.TaMhtEditorWidget_margin__hoTdO {
    margin: 6px;
}

.TaMhtEditorWidget_row__21ZqW,
.TaMhtEditorWidget_counter__Ms03L {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
}
.TaMhtEditorWidget_column__uIxnA {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
}
.TaMhtEditorWidget_half__2Yew_ {
    flex: 0 1 50%;
    width: 50%;
    /*position: relative;*/
}
.TaMhtEditorWidget_suggestionsWrapper__3PtyP {
    flex: 0 1 50%;
    width: 50%;
    position: relative;
}
.TaMhtEditorWidget_suggestions__2QgGu {
    /*flex: 1 1 100%;*/
    display: block;
    position: absolute !important;

    /*position: absolute;*/
    top: 6px;
    left: 6px;
    right: 6px;
    bottom: 6px;
    height: auto !important;
}

.TaMhtEditorWidget_fill__22nwi {
    display: flex;
    flex: 1 1 100%;
    width: 100%;
}

.TaMhtEditorWidget_hspace__1q11h {
    display: flex;
    flex: 1 1 auto;
}

.TaMhtEditorWidget_counter__Ms03L {
    align-items: center;
}
.TaMhtEditorWidget_counter__Ms03L span {
    padding: 12px 6px;
    white-space: nowrap;
}
.ta-input--dropdown {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: stretch;
    padding: 0;
    height: 32px;


    border: 1px solid #ced7db;
    box-shadow: 0 0 0 rgba(33, 149, 242, 0);

    transition: border 0.3s, box-shadow 0.3s;
}

.ta-input--dropdown {
    border-radius: 4px;
    background: white;
    /*background: rgba(206, 215, 219, 0.25);*/
}


.ta-input--dropdown.ta-input--focus {
    box-shadow: 0 0 5px rgba(33, 149, 242, 1) /* #03a9f4 */;
    border: 1px solid #03a9f4;
}

.ta-input--dropdown > select {
    /*position: absolute;*/
    /*display: block;*/
    /*top: 0;*/
    /*left: 0;*/
    /*right: 0;*/
    /*bottom: 0;*/
    border: 0;
    width: 100%;
    margin: 0;
    padding: 0 32px 0 8px;
    /*padding: 0 4px;*/

    background: transparent;
    outline: none;
    /*color: transparent;*/
    cursor: pointer;
    /*user-select: none;*/
    color: #222d32;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;

    -o-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    z-index: 1;
}

.ta-input--dropdown.ta-input--focus .ta-input__icon {
    color: #03a9f4;
}

.ta-input--dropdown .ta-input__icon {
    margin: 3px 4px;
}

.ta-input--dropdown .ta-input__icon:first-child {
    margin-right: 0;
}

.ta-input--dropdown .ta-input__label {
    display: none;
    padding: 0 4px 0 8px;
    order: 1;
    flex: 1 1;
    max-width: calc(100% - 24px);
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 24px;
}

.ta-input--dropdown .ta-input__icon:last-child {
    position: absolute;
    right:0;
    z-index: 0;
}

.ta-input--dropdown--list > select {
    padding: 8px;
}



.ta-input--date {
    font-weight: normal;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;

    /*border: 1px solid #DDDDDD;*/
    border: 1px solid #ced7db;

    transition: border 0.3s, box-shadow 0.3s;

    height: 32px;

    padding: 0 6px;
    background: white;

    border-radius: 4px;
    box-shadow: 0 0 0 rgba(33, 149, 242, 0);

}

.ta-input--date,
.ta-input--date * {
    box-sizing: border-box;
}

.ta-input--date.ta-input--focus {
    box-shadow: 0 0 5px rgba(33, 149, 242, 1) /* #03a9f4 */;
    border: 1px solid #03a9f4;
}
.ta-input--date.ta-input--invalid {
    border: 1px solid #e53935;
}
.ta-input--date.ta-input--invalid.ta-input--focus {
    box-shadow: 0 0 5px #e53935;
}

.ta-input--date .ta-input__button {
    color: #DDD;
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
    cursor: pointer;
}
.ta-input--date.ta-input--empty .ta-input__button {
    opacity: 0;
    cursor: default;
}


.ta-input--date .ta-input__button:hover {
    color: #333;
}

.ta-input--date input {
    outline: none;
    flex: 1 1;
    border: 0;
    padding: 0 6px;
    margin: 0;
    font-size: 14px;
    line-height: 18px;
    background: #FFFFFF !important;
    order: 2;
}

.ta-input--date .ta-input__icon:first-child {
    order:1
}
.ta-input--date input {
    order:2
}
.ta-input--date .ta-input__button {
    order:3
}
.ta-input--date .ta-input__button + .ta-input__icon {
    order:4
}

.ta-input--date .ta-input__icon:first-child + input {
    max-width: calc(100% - 28px);
}
.ta-input--date .ta-input__icon + .ta-input__button + input {
    max-width: calc(100% - 48px);
}
.ta-input--date .ta-input__icon + .ta-input__button + .ta-input__icon + input {
    max-width: calc(100% - 72px);
}

.employee-reference-box .box-header {
  background-color: #dde2ea;
  border-radius: 3px 3px 0 0;
}

a, u {
  cursor: pointer;
}
textarea {
  resize: vertical;
}
/*
* ==============================================================================
* Bootstrap
* ==============================================================================
*/
.text-danger {
  color: #ff0000;
}
.form-group {
  margin-bottom: 10px;
}
.form-condensed .form-group {
  margin-top: 0;
  margin-bottom: 5px;
}

.form-horizontal .control-label {
  margin-bottom: inherit;
  padding-top: inherit;
}

.form-control,
.form-group-sm .form-control,
.input-group-sm > .form-control {
  border-radius: 0;
  height: 24px;
  padding: inherit;
  padding-left: 10px;
}

.table {
  margin-bottom: 0;
}
.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  vertical-align: middle;
}

.modal {
  background: rgba(0, 0, 0, 0.1);
}

.modal-backdrop.in {
  opacity: 0;
}

.modal-body img {
  max-width: 100%;
}

.modal-content {
  border-radius: 3px;
}

#job-invoice-tabs .table-layout-fixed td{
  overflow: auto;
  white-space: normal;
}

/*
* ==============================================================================
* AdminLTE
* ==============================================================================
*/
body {
  background-color: #ecf0f5;
}

.wrapper,
.fixed .wrapper {
  overflow: inherit;
}

.sidebar-menu > li.menu-open > a {
  color: #b8c7ce
}
.sidebar-menu > li.menu-open.active > a {
  color: #fff;
}
.input-group-addon {
  padding: 0 12px;
}

.input-group-sm > .input-group-btn > .btn {
  height: 24px;
  padding: 0px 15px;
}
.input-group-btn:last-child > .btn {
  border-radius: 0;
}
.btn-box-tool:hover {
  background: rgba(0, 0, 0, 0.1);
}
.alert-warning {
  color: #8a6d3b !important;
  background-color: #fcf8e3 !important;
  border-color: #faebcc !important;
}
.nav-tabs-custom > .tab-content {
  padding: 0;
}
.nav-tabs-custom > .tab-content .table > tbody > tr:first-child th, .nav-tabs-custom > .tab-content .table > tbody > tr:first-child td {
  border-top: 0;
}
.nav-tabs-custom > .nav-tabs > li.active {
  border: 0;
}
.box-header, .modal-header, .modal-footer {
  background: rgba(0, 0, 0, 0.02);
}

.box.box-solid.box-success .no-padding {
  /*without this, Some table border in chrome will be disappeared when browser zoom out*/
  padding:1px !important;
}

.Select-arrow-zone {
  background: #f4f4f4;
  padding-left: 6px;
  padding-right: 0;
}

.checkbox.disabled label {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
}

.bg-green .box-footer, .bg-green-light .box-footer {
  color: #333;
  background: #fff;
}

/*
* ==============================================================================
* React Select
* ==============================================================================
*/
.Select.is-open, .Select-control,
.Select-control {
  height: inherit; /*1*/
  border-radius: 0;
  border-color: #d2d6de;
  box-sizing: border-box;
  overflow: inherit;
}
.Select-control .Select-input {
  height: 24px; /*1*/
}
.Select-placeholder, .Select--single > .Select-control .Select-value {
  line-height: 24px; /*1*/
}
.Select-control .Select-input > input {
  line-height: 15px;
  padding: 6px 0 12px;
}
.Select-clear {
  font-size: 16px;
  line-height: 1.4;
}
.Select.has-value.Select--single > .Select-control .Select-value .Select-value-label,
.Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
  color: #555;
  font-size: 12px;
}
.Select .Select-menu-outer {
  font-size: 12px;
  z-index: 10000;
}
.Select--multi .Select-value {
  margin-top: 2px;
  margin-bottom: 2px;
}
/*
* ==============================================================================
* React Date picker
* ==============================================================================
*/
.react-datepicker-popper {
  z-index: 10;
}
/*
* ==============================================================================
* Label Group
* ==============================================================================
*/
label {
  margin-bottom: 2px;
}
.label-group {
  display: inline-block;
  padding: .5em .6em;
}
/*
* ==============================================================================
* SalesTarget
* ==============================================================================
*/
.saleTargetPage .ag-body-container {
  text-align: right;
}
.salestarget-meet {
  font-weight: 700;
  font-size: 1.2em;
}


.pagination {
  margin: 0;
  float: right;
}


.modal-dialog .carousel-indicators {
  visibility: hidden;
}
#root .login-page {
  background-image: url(/static/media/tokyo3980.8e97bfb4.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-logo {
  margin-bottom: 30px;
}

.login-logo-text {
  font-weight: 200;
  color: rgba(26, 100, 192, 1.00);
  font-size: 28px;
}

.login-box-body {
  position: relative;
}

.login-box-body .form-control {
  height: 34px;
}
.ui-datatable-tablewrapper .ui-rowgroup-header {
  background: #f9f9f9;
  font-weight: 600;
}

.user-about-item-title {
    display: block;
    margin-bottom: 3px;
}

.quick-edit-box .col-xs-1,
.quick-edit-box .col-sm-1,
.quick-edit-box .col-md-1,
.quick-edit-box .col-lg-1,
.quick-edit-box .col-xs-2,
.quick-edit-box .col-sm-2,
.quick-edit-box .col-md-2,
.quick-edit-box .col-lg-2,
.quick-edit-box .col-xs-3,
.quick-edit-box .col-sm-3,
.quick-edit-box .col-md-3,
.quick-edit-box .col-lg-3,
.quick-edit-box .col-xs-4,
.quick-edit-box .col-sm-4,
.quick-edit-box .col-md-4,
.quick-edit-box .col-lg-4,
.quick-edit-box .col-xs-5,
.quick-edit-box .col-sm-5,
.quick-edit-box .col-md-5,
.quick-edit-box .col-lg-5,
.quick-edit-box .col-xs-6,
.quick-edit-box .col-sm-6,
.quick-edit-box .col-md-6,
.quick-edit-box .col-lg-6,
.quick-edit-box .col-xs-7,
.quick-edit-box .col-sm-7,
.quick-edit-box .col-md-7,
.quick-edit-box .col-lg-7,
.quick-edit-box .col-xs-8,
.quick-edit-box .col-sm-8,
.quick-edit-box .col-md-8,
.quick-edit-box .col-lg-8,
.quick-edit-box .col-xs-9,
.quick-edit-box .col-sm-9,
.quick-edit-box .col-md-9,
.quick-edit-box .col-lg-9,
.quick-edit-box .col-xs-10,
.quick-edit-box .col-sm-10,
.quick-edit-box .col-md-10,
.quick-edit-box .col-lg-10,
.quick-edit-box .col-xs-11,
.quick-edit-box .col-sm-11,
.quick-edit-box .col-md-11,
.quick-edit-box .col-lg-11,
.quick-edit-box .col-xs-12,
.quick-edit-box .col-sm-12,
.quick-edit-box .col-md-12,
.quick-edit-box .col-lg-12 {
    padding-right: 10px;
    padding-left: 10px;
}
.quick-edit-box .form-group{
  margin-bottom: 2px;
}
.quick-edit-box .col-lg-9 .form-group {
  margin-bottom: 3px;
}
.quick-edit-box .btn-sm /*, .btn-group-sm > .btn*/{
  padding: 1px 10px;
}
.form-horizontal .quick-edit-box .form-group-sm .control-label{
  font-size: 14px;
}
.quick-edit-box .checkbox{
  padding-top: 0;
}
.quick-edit-box .row{
  margin-right: -10px;
  margin-left: -5px;
}

.quick-edit-box .quick-edit-notification {
  padding: 0 10px;
}
.quick-edit-box .quick-edit-notification label {
  margin-bottom: 5px;
}
.quick-edit-box .quick-edit-notification .form-group {
  margin-left: 0;
  margin-right: 0;
}
.job-detail .job-detail-tools .Select {
  font-size: 12px;
  margin-top: 5px;
}
.job-detail .job-detail-tools > li {
  float: left;
}

.job-assignment-box .table tbody > tr > td {
  padding: 0 5px;
}

.job-work-flow {
  display: flex;
  flex-wrap: wrap;
  margin-right:-10px; 
}
.job-work-flow .box {
  margin-bottom: 20px;
  width: 24%;
  align-self: flex-start;
}

.job-work-flow .box:not(:last-child) {
  margin-right: 1%;
}

.job-work-flow .box-header {
  background: transparent;
}

.job-work-flow .box-header .box-title {
  font-size: 17px
}

.job-work-flow .widget-user-2 .box-header .box-title {
  font-size: 16px;
}

.job-work-flow .widget-user-2 .widget-user-header {
  padding: 0;
}

.job-work-flow .widget-user-2 .widget-user-image > img {
  width: 35px;
  margin: 0 10px 10px;
}

.job-work-flow .widget-user-2 .widget-user-username,
.job-work-flow .widget-user-2 .widget-user-desc {
  margin-left: 40px;
}

.job-work-flow .widget-user-2 .widget-user-username {
  margin-top: 3px;
  font-size: 12px;
  font-weight: normal;
}

.job-work-flow .widget-user-2 .widget-user-desc {
  font-size: 11px;
}

.job-work-flow .box-footer .nav-stacked .file-name {
  width: 80%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}


@media (max-width: 768px) {
  .job-work-flow .box {
    width: 100%;
  }
}

@media (max-width: 992px) {
  .job-work-flow .box {
    width: 100%;
  }
}

/*****bof CheckboxGroupField******/
.row.welled {
  min-height: 20px;
  padding: 10px 0;
  margin-bottom: 0px;
  background-color: #f5f5f5;
  border: none;
  border-radius: 0px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
}

.checkbox.disabled label,
.has-error .checkbox.disabled label {
  /* text-decoration: line-through;
  text-decoration-color: #333; */
  color: #aaa;
}
/*****eof CheckboxGroupField******/

.success-message-confirm {
  color: #3c8dbc;
}
.success-message-confirm .fa {
  font-size: 8em;
}
.success-message-confirm span {
  font-size: 1.8em;
}

.job-note-box p {
    margin-bottom: 3px;
}

.message {
  /* padding: 10px; */
  /* background: #f7f7f7; */
}

.message:not(:last-child) {
  border-bottom: solid 1px #CCC;
}

.message.mht-message .message-header {
  background-color: #f39c12;
  border-bottom-color: #f39c12;
}
.message.mht-message .message-footer {
  background-color: #F6B363;
  /* border-top-color: #F6B363; */
}
.message.mht-message .message-body {
  background-color: #F6B363;
}

.message .message-header {
  padding: 10px 10px 8px 10px;
  border-bottom: 1px solid #eee;
  background: #e4e8f1;
}
.message .message-body {
  color: #555;
  padding: 8px 10px 10px 10px;
  font-size: 0.85em;
}
.message .message-body p {
  margin: 0;
}
.message .message-footer {
  /* margin-top: 10px; */
  padding: 8px 10px 10px 10px;
  border-top: solid 1px #eee;
}

.message .message-footer .badge {
  cursor: pointer;
}

.message .user-block img {
  width: 35px;
  height: 35px;
}
.message .user-block .username {
  font-size: 13px;
  margin-left: 45px;
}

.message .user-block .message-meta {
  color: #666;
  font-size: 0.85em;
  font-weight: normal;
  margin-top: 3px;
}

.message .user-block .description {
  font-weight: 400;
  font-size: 12px;
  margin-left: 45px;
}

.file-upload-modal .file-upload {
  height: 200px;
  background-size: 100px auto;
  background-image: url(/static/media/cloud-upload.7196b1d3.svg);
  background-color: #d2d6de;
  background-position: center 35%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.file-upload-modal .file-upload p {
  margin-top: 100px;
  font-size: 18px;
  text-align: center;
  color: #999;
}

.file-upload-modal .file-upload:hover,
.file-upload-modal .file-upload.active {
  background-image: url(/static/media/cloud-upload-o.5ac1bb7c.svg);
  background-color: #357CA5;
}

.file-upload-modal .file-upload:hover p,
.file-upload-modal .file-upload.active p {
  color: #fff;
}

.file-upload-modal .nav-stacked > li + li {
  margin-top: 0;
}

.file-upload-modal .nav-stacked > li {
  border-top: 1px solid #f4f4f4;
}

.file-upload-modal .nav-stacked > li > a {
  border-left: 0;
}
.file-upload-modal .nav-stacked > li > a.no-files-selected {
    text-align: center;
}

.file-upload-modal .nav-stacked > li > a:hover,
.file-upload-modal .nav-stacked > li > a:active,
.file-upload-modal .nav-stacked > li > a:focus {
  background: #fff;
  cursor: default;
}

.rc-time-picker {
  display: block;
}

.rc-time-picker-input {
  height: 24px;
  border-color: #d2d6de;
  outline: none;
  border-radius: 0;
  padding: 0 12px;
}

.has-error .rc-time-picker-input {
  border-color: #dd4b39;
}

.splash-logo {
  left: 50%;
  top: 50%;
  margin-left: -25px;
  margin-top: -25px;
  z-index: 2;
  position: absolute;
  width: 50px;
}

.splash-loader::before,
.splash-loader::after {
  left: 50%;
  top: 50%;
  margin-left: -45px;
  margin-top: -45px;
  position: absolute;
  vertical-align: middle;
  background: #3c8dbc;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  z-index: 1;
}

.splash-loader::before {
  content: "";
  -webkit-animation: bounce 1.5s infinite;
          animation: bounce 1.5s infinite;
}

.splash-loader::after {
  content: "";
  -webkit-animation: bounce 1.5s -0.4s infinite;
          animation: bounce 1.5s -0.4s infinite;
}

@keyframes bounce {
  0% {
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    opacity: 1;
  }
  100% {
    transform: scale(1.6);
    -webkit-transform: scale(1.6);
    opacity: 0;
  }
}

@-webkit-keyframes bounce {
  0% {
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    opacity: 1;
  }
  100% {
    transform: scale(1.6);
    -webkit-transform: scale(1.6);
    opacity: 0;
  }
}

.form-group .react-datepicker-wrapper,
.form-group .react-datepicker-wrapper .react-datepicker__input-container {
  display: block;
}

.react-datepicker-wrapper .form-control.has-error {
  border-color: #dd4b39;
}

.react-datepicker-wrapper .form-control.highlighted {
  font-size: 1.3em;
  font-weight: 400;
}

.form-control.is-today {
  background: #e7ffe9;
  color: #299e29;
}

.react-datepicker{
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.35);
  border: 1px solid #ccc;
  border-radius: 0;
  color: #666;
  font-size: 1.3rem;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
  border-radius: 2px;
  width: 3rem;
  line-height: 3rem;
  font-size: 0.85em;
  margin: 1px;
}
.react-datepicker__day--sun, .react-datepicker__day-name:first-child{
  color:#ff7878;
}
.react-datepicker__day--sat, .react-datepicker__day-name:last-child{
  color: #4797fa;
}
.react-datepicker__day-name, .react-datepicker__time-name{
  color: #666;
}
.react-datepicker__current-month, .react-datepicker-time__header{
  font-weight: 200;
  font-size: 1.1em;
}
.react-datepicker__header, .react-datepicker__today-button{
  border: 0;
}
.react-datepicker__header{
  padding-top: 5px;
  padding-bottom: 2px;
}
.react-datepicker__today-button{
  font-weight: 200;
  padding: 2px 0;
  color: #299e29;
  background: #fff;
  border-top: 1px solid #f0f0f0;
}
.react-datepicker__today-button:hover{
  color: #333;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover{
  background:#e0e0e0;
  color: #333;
  border-radius: 50%;
}
.react-datepicker__day--today{
  background-color: #299e29;
  color: #ffffff;
  border-radius: 50%;
}
.react-datepicker__day--today:hover{
  background-color: #1d711d;
  color: #ffffff;
}
.react-datepicker__day:hover{
  border-radius: 50%;
}
.react-datepicker__day--outside-month{
  opacity: 0.65;
}
.react-datepicker__day-name{
  line-height: inherit;
}
.react-datepicker__navigation{
  border-width: 0.65rem;
  top: 7px;
  outline: none;
}
.react-datepicker__navigation--previous{
  border-right-color: #797979;
}
.react-datepicker__navigation--next{
  border-left-color: #797979;
}

.date-range-form-field {
  display: flex;
  align-items: center;
}

.date-range-form-field div:not(.date-range-form-field-sep) {
  flex-grow: 1;
}
.date-range-form-field .date-range-form-field-sep {
    margin: 0 6px;
    font-weight: 700;
}

/*
* ==============================================================================
* quilljs
* ==============================================================================
*/

.ql-snow .ql-color-picker .ql-picker-item {
  border-color: #eeeeee;
}

.ql-toolbar.ql-snow {
  border-top-width: 0;
  border-right-width: 0;
  border-left-width: 0;
  border-bottom: 1px solid #f4f4f4;
}

.ql-container.ql-snow {
  border-width: 0;
}

.ql-container.ql-snow .ql-editor {
  min-height: 200px;
}

.has-error .ql-toolbar.ql-snow,
.has-error .ql-container.ql-snow {
  border-color: #dd4b39;
  border-width: 1px;
}

.form-editor {
  margin-bottom: 0!important;
}


.main-header .navbar .nav > li.refresh-btn.disabled {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
}

.main-header .navbar .nav > li.refresh-btn.disabled > a:hover {
    background: transparent;
    color: #fff;
}
.ag-react-container a,
.ag-react-container span {
  display: block;
}
.ag-react-container div,
.ag-react-container a,
.ag-react-container span,
.ag-react-container strong {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ag-layout-normal .ag-body-container {
  min-width: 100%!important;
}
.ag-theme-balham {
  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1em;
}
.ag-theme-balham .ag-row-even {
  background-color: #eff7ef;
}
.ag-theme-balham .ag-row-urgent {
  background-color: #f2dede;
}
.ag-theme-balham .ag-row-urgent.ag-row-hover,
.ag-theme-balham .ag-row-even.ag-row-hover {
  background-color: #ECF0F1;
}
.ag-theme-balham .ag-row-even.ag-row-selected {
  background-color: #b7e4ff;
}
.ag-theme-balham .ag-header-row {
  border-bottom: inherit;
}
.ag-pinned-right-header .ag-header-row,
.ag-pinned-right-header .ag-header-row{
  border-left: 1px solid #ccc;
}
.ag-pinned-right-cols-viewport .ag-row, .ag-pinned-right-header .ag-header-row, .ag-pinned-right-floating-bottom{
  box-shadow: inset 15px 0px 16px -21px rgba(0,0,0,0.90);
}
.ag-pinned-left-header .ag-header-row{
  border-right: 1px solid #ccc;
}
.ag-pinned-left-cols-viewport .ag-row, .ag-pinned-left-header .ag-header-row, .ag-pinned-left-floating-bottom{
  box-shadow: inset -15px 0px 16px -21px rgba(0,0,0,0.90);
}
.ag-theme-balham .ag-header-cell-resize::after{
  height: inherit;
  margin: 0;
}
.ag-theme-balham .ag-header-cell,
.ag-theme-balham .ag-header-group-cell,
.ag-theme-balham .ag-cell {
  padding-left: 6px;
  padding-right: 4px;
}
.ag-theme-balham .ag-cell.ag-text-right {
  text-align: right;
  padding-right: 7px;
}
.ag-theme-balham .ag-double-row .ag-cell{
  line-height: 40px;
}
.ag-theme-balham .ag-floating-bottom .ag-double-row .ag-cell{
  line-height: inherit;
}
.ag-theme-balham .ag-cell .detailed-cell {
  line-height: 1.35em;
  overflow: hidden;
  padding-top: 1px;
}

/*use this to add border to all cell in the list Table*/
/* .ag-theme-balham .ag-layout-normal .ag-cell{
  border-right:1px solid rgba(0, 0, 0, 0.05);
} */

.ag-theme-balham button.ag-paging-button{
  background-color: #fff;
  border-radius: 50%;
  width: 21px;
  height: 21px;
}
.ag-theme-balham .ag-paging-panel {
  background: linear-gradient(45deg, rgba(46,142,122,1), rgba(41,158,41,1));
  /* background-color: #cfd2d4; */
  border-top: 0;
  color: #ffffff;
}
.ag-theme-balham button:hover{
  opacity: 1;
  background-color: #fff;
}
.ag-theme-balham .ag-cell-no-focus:focus {
  outline: none;
}
.ag-theme-balham .ag-header{
  border-bottom: 1px solid #299e29;
  font-weight: 700;
  color: #333;
}
.ag-theme-balham .ag-filter-body{
  padding-right: inherit;
}
.ag-layout-normal .ag-floating-bottom{
  background: #f5f7f7;
  font-weight: 600;
  color: #228222;
  border-top: 1px solid #299e29;
}
.ag-floating-bottom{
  font-size: 1.15em;
}
#global-tabset-pane-SalesReport .ag-floating-bottom{
  font-size: 1em;
}
.ag-layout-normal .ag-floating-bottom .ag-react-container{
  /* font-size:0.85em; */
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ag-layout-normal .ag-floating-bottom .ag-floating-bottom-container{
  font-weight: normal;
  /* font-size: 1.1em; */
}
.ag-layout-normal .ag-floating-bottom .ag-row {
  background-color: inherit;
}
.cell-wrap-text {
  white-space: normal !important;
}
.deadline-in-row {
  display: flex;
}
.deadline-in-row > .deadline-in-row-sep {
  padding: 0 3px;
}
.ag-jobNumber{
  font-weight: 600;
}
.ag-theme-balham .ag-row-drag{
  width: 18px;
  margin-left: -4px;
  margin-top: 3px;
}
/*check this on Safari - to stop "daily schdule" page's left and middle column don't show scroll bar any more- does't it work?*/
.ag-pinned-left-cols-viewport, .ag-body-viewport-wrapper{
  overflow: hidden;
}

.form-group .Select.is-disabled > .Select-control {
  background: #eee;
}

.form-group.has-error .Select-control {
  border-color: #dd4b39
}

.main-sidebar > .sidebar {
  height: calc(100vh - 40px);
}

.sidebar-mini:not(.sidebar-collapse) .main-sidebar > .sidebar {
  overflow: auto;
}
.sidebar-mini.sidebar-collapse .main-sidebar > .sidebar {
  overflow: unset;
}

.main-sidebar > .sidebar > .sidebar-menu li > a > .pull-right-container > .fa-angle-left {
  transition: transform 0s ease;
}

/* .main-sidebar > .sidebar > .sidebar-menu li > a {
  padding-left: 7px;
}

.main-sidebar > .sidebar > .sidebar-menu .treeview-menu {
  padding-left: 20px;
} */

.main-sidebar > .sidebar > .sidebar-menu > li.menu-open > a {
  color: #b8c7ce;
  background-color: #222d32;
}

.main-sidebar > .sidebar > .sidebar-menu > li.active > a {
  color: #fff;
  background-color: #1e282c;
}



.sidebar-mini.sidebar-collapse .main-sidebar > .sidebar > .sidebar-menu li > a > .fa-angle-left,
.sidebar-mini.sidebar-collapse .main-sidebar > .sidebar > .sidebar-menu li > a > .pull-right-container > .fa-angle-left {
  transform: rotate(-90deg);
}
/*global tabs*/
#global-tabset > .tab-content > .tab-pane {
  /* overflow: hidden; */
}
.content-wrapper .nav-tabs li{
  display: inline-block;
  float: none;
}
#global-tabset > .nav-tabs .tab-remove {
  margin-left: 10px;
  cursor: pointer;
}
#global-tabset > .nav-tabs .tab-title {
  margin-right: 10px;
}
#global-tabset > .nav-tabs li {
  margin-bottom: 0;
  margin-top: 3px;
}
#global-tabset > .nav-tabs li.active a {
  background-color: #ecf0f5;
  color: #299e29;
  border-color: #ecf0f5;
  font-weight: 600;
}
#global-tabset > .nav-tabs li a {
  padding: 5px 15px;
  color: #b8c7ce;
  border-radius: 0;
  background-color: rgba(34, 45, 50, 0.58);
  margin-right: 5px;
}
#global-tabset > .nav-tabs li a:hover {
  color: #444;
  background-color: #ecf0f5;
  border-color: #ecf0f5;
}
#global-tabset .tab-close {
  cursor: pointer;
}
#global-tabset .label-default {
  background-color: rgba(0, 0, 0, 0.1);
}
#global-tabset .content .nav-tabs {
  border-bottom: 0;
}
#global-tabset .content .nav-tabs li.active a .label-default {
  background-color: rgba(41, 158, 41, 1.0);
  color: #fff;
}
#global-tabset .border-tab-content-page > .tab-content{
  /*use ".border-tab-content-page.content " for page with .tab-content that need border in the content*/
  border:1px solid #bdc3c7;
  margin-top: -1px;
  background: #ffffff;
}

.tab-content .content .nav-tabs li a {
  margin-top: 2px;
  border: 1px solid #bdc3c7;
  background: linear-gradient(#dddfe2, #dddfe2, #dddfe2, #dddfe2, #dddfe2, #ccc);
  color: #000000;
  padding: 2px 15px 7px;
}
.tab-content .content .nav-tabs li.active a {
  margin-top: 0;
  border: 1px solid #bdc3c7;
  /* border-color: #bdc3c7; */
  border-bottom: 0;
  border-top: 4px solid #299e29;
  background: #ffffff;
  color: rgba(41, 158, 41, 1.0);
}
.tab-content .content .nav-tabs-custom .nav-tabs{/*re-stye tab in tab*/
  box-shadow: 0px 8px 15px -10px rgba(0,0,0,0.2);
  border-bottom: 1px solid #dfdfdf;
  margin: 0 0 25px;
  padding: 8px 0 0 0;
}
.tab-content .content .nav-tabs-custom > .nav-tabs > li{
  margin: 0 15px;
  margin-bottom: -5px;
  z-index: 2;
  font-size: 1.15em;
}
.tab-content .content .nav-tabs-custom .nav-tabs li a{
  background: none;
  padding: 0 2px 5px 2px;
  border:0;
}
.tab-content .content .nav-tabs-custom .nav-tabs li.active {
  border-bottom: 5px solid #299e29;
}

/*@kapost teb slider module*/
.wrapper,
.fixed .wrapper {
  overflow: inherit;/*reset overflow here or sticky in ".component-slider" won't work*/
}
.component-slider {
  position: -webkit-sticky;
  position: sticky;
  top: 50px;
  z-index: 100;
  background-color: rgba(26, 34, 38, 0.95);
}
.component-slider .caret {
  height: 100%;
  width: 15px;
  background-color: rgba(41, 158, 41, 1.0);
  color:#ffffff;
}
.component-slider .caret:hover {
  background-color: rgba(56, 191, 107, 0.90);
}

.component-slider .slider-container {
  margin: 0 15px;
  font-size: 0; /*or display: flex; + remove font-size in ".component-slider .slider-content"*/
}

.component-slider .slider-content {
  font-size: 14px;
}

.component-slider .slider-content > .nav-tabs {
  border-bottom: 0;
}

.component-slider .nav-tabs li a {
  margin-top: 4px;
  border: 0;
  border-radius: 3px 3px 0 0;
  color: #aeb6bc;
  padding: 7px 15px;
}
.component-slider .nav-tabs li a:hover {
  background-color: rgba(255, 255, 255, 0.15);
  color: #ccc;
}

.component-slider .nav-tabs li.active a,
.component-slider .nav-tabs li.active a:hover,
.component-slider .nav-tabs li.active a:focus {
  background-color: #ecf0f5;
  color: rgba(41, 158, 41, 1.0);
  font-weight: 600;
  border:0;
}

.navbar {
  min-height: 41px;
  margin-bottom: 14px;
}
.navbar-brand {
  padding-top: 12px;
  padding-bottom: 0;
  height: auto;
}
.tab-content .navbar-nav > li > a {
  padding-top: 9px;
  padding-bottom: 0;
}
.navbar-form {
  padding: 0;
  margin: 7px 0 0;
}

.navbar-inverse .navbar-nav > .disabled > a,
.navbar-inverse .navbar-nav > .disabled > a:hover,
.navbar-inverse .navbar-nav > .disabled > a:focus {
  color: #fff;
}

#global-tabset-pane-Calendar .content{
  min-height: 100vh; /*fix "more" popup-box in the last row being cut off if content heigh is not tall enough.*/
}
.fc-view-container {
  height: 100%;
}
.fc .fc-body td {
  border-bottom: 0 !important;
}
.fc-day-grid-event {
  text-align: center;
  margin: 0 3px 2px;
}
.fc-day-grid-event .fc-content {
  padding: 0.2rem 0.25rem 0.3rem;
  white-space: normal;
  line-height: 1.25em;
}
.fc-day-grid-event .fc-time {
  font-weight: normal !important;/* display: block; */
}
.fc-day-grid-event .fc-title {
  font-weight: bold !important;
}
.fc-view, .fc-view > table {
  /*override default "MORE" link to bring popup tootip ABOVE everything*/
  z-index: auto !important;
}
.fc-head th.fc-widget-header {
  color: #8b9898;
  padding: 5px 0;
}
.fc-head th.fc-widget-header.fc-sun {
  color: #ff7878;
}
.fc-head th.fc-widget-header.fc-sat {
  color: #4797fa;
}
.fc td.fc-more-cell{
  padding-top: 2px;
}
a.fc-more {
  background-color: #f4f4f4;
  color: #444;
  border: 1px solid #acacac;
  border-radius: 3px;
  padding: 1px 8px 2px;
  font-size: 0.83em !important;
}
a.fc-more:hover {
  background: #e7e7e7;
  text-decoration: none !important;
}
.fc-event {
  font-size: 1em;
  border-radius: 24px;
  border-color: rgba(255,255,255,0.4);
}
.fc-event:hover {
  -webkit-filter: brightness(105%);
          filter: brightness(105%);
}


.fc-event.event-assignment-finished{
  background-color: #e2e4ea;
  border-color: #e2e4ea;
  color: #333;
}
.fc-event.event-assignment-expired {
  background-color: #605ca8;
}
.fc-event.event-assignment-created{
  background-color: #ffffff;
  color: #333;
  border-color: rgba(0,0,0,0.25);
}

.fc-event.event-holiday{
    color: #333;
    border-color: #fad16a;
}
.event-holiday-requested{
  background-image: repeating-linear-gradient(145deg, #ffe6a6, #ffe6a6 5px, #fad16a 5px, #fad16a 8px);
}
.event-holiday-approved{
  background:#fad16a;
}
.event-holiday-declined{
  background-image: repeating-linear-gradient(0deg, #fff3d3, #fff3d3 5px, #fff 5px, #fff 8px);
}
a.holiday-available-for-work{
  background: rgba(205,238,157,0.5);
  color: #4a6337;
  border-color: #92c46d;
}
a.holiday-available-for-work:hover{
  background: rgba(205,238,157,0.65);
  color: #4a6337;
}
.fc-agendaWeek-view .fc-event, .fc-agendaDay-view .fc-event {
  border-radius: 3px;
}
.fc-basic-view .fc-body .fc-row {
  height: 100%;
  min-height: 6em;
}
.fc-event .fc-bg {
  opacity: 0 !important;
}
.fc .fc-axis {
  padding: 0 !important;
}
.calendar-content {
  background: #ffffff;
  display: flex;
}
.calendar-content .fc-unthemed {
  margin-top: 0;
  flex: 7 1;
}
.calendar-user {
  vertical-align: top;
  text-align: center;
  font-size: 0.95em;
  line-height: 1.2em;
  font-weight: 200;
  border-right: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
  flex:0.5 1;
  padding: 0 5px;
  word-break: break-all;
  box-shadow: inset -15px 0px 16px -21px rgba(0,0,0,0.90);
}
.has-header.calendar-user {
  padding-top: 20px;
}
.fc-event.dayoff-available {
  font-weight: 700 !important;
  color: #666666 !important;
  background: rgba(255, 255, 255, 0.25) !important;
  border: 0 !important;
}
.dayoff-box {
  color: #808080 !important;
  border-color: #999 !important;
  background-color: #ccc !important;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 1.5rem 1.5rem !important;
}
.calendar-content .user-image {
  width: 40px;
  height: 40px;
  display: block;
  border: 4px solid #d4d4d4;
  margin: 8px auto 3px;
}
.calendar-content .user-image.available-now {
  border-color: #00a65a;
}
.calendar-controler {
  padding: 8px 5px;
  background: #ffffff;
}
.calendar-controler .btn {
  padding: 0 20px;
  font-size: 24px;
}
.calendar-user .btn {
  margin-bottom: 10px;
  margin-top: 3px;
}
 @-moz-document url-prefix() {
 .fc .fc-body td {
 height: 80%;
}
 .fc .fc-body .fc-bg td {
 height: auto;
}
}
.fc-view table {
  height: 100%;
}
.fc-basic-view {
  height: 100%;
}
.calendar-content .fc-basic-view .fc-head td {
  height: 20px;
  line-height: 20px;
}
.calendar-content .fc-day-grid-container {
  height: 100% !important;
  overflow: visible !important;
}
.fc-scroller.fc-day-grid-container {
  height: auto!important;
}
.fc-day-grid {/* remove height 100% for firfox - user calendar "week" and "day"*/
/* height: 100%; */
}
.user-profile-link {
  display: block;
}
.fc-toolbar.fc-header-toolbar {
  margin: 0;
  padding-top: 0;
  padding-bottom: 4px;
}
.fc-toolbar.fc-header-toolbar h2 {
  font-size: 1.65em;
}
.fc-state-down, .fc-state-active {
  box-shadow: none;
  background-color: #e9e9e9;
}
.fc-bgevent {/*without this, weekend background will be green*/
  background-color: #d7d7d7;
}
.fc-unthemed .fc-popover {
  background-color: rgba(255, 255, 255, 0.85);
  box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14),0 9px 46px 8px rgba(0,0,0,0.12),0 11px 15px -7px rgba(0,0,0,0.2);
  border-color: #aaaaa9;
  border-radius: 4px;
  z-index: 5;
}
.fc-more-popover .fc-event-container{
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 75vh;
  padding: 8px 3px;
}
.fc-unthemed .fc-popover .fc-header{
  background: #dddfe2;
  min-height: 31px;
  line-height: 30px;
  border-radius: 4px 4px 0 0;
  font-weight: 600;
}
.fc-unthemed .fc-popover .fc-header .fc-close{
  margin-top: 10px;
  font-weight: 400;
}
.fc-unthemed td.fc-today{
  background: #e7ffe9;
}
.job-delivery-detail-tabs-container > .nav-tabs {
  border-bottom: 0;
}

.job-delivery-detail-tabs-container > .nav-tabs > li > a {
  color: #444;
  border: 0;
  font-weight: 600;
  font-size: 14px;
}

.job-delivery-detail-tabs-container > .nav-tabs > li > a:hover {
  background: none;
}

.job-delivery-detail-tabs-container > .nav-tabs > li.active > a {
  color: #337ab7;
  background: none;
  border: 0;
  cursor: pointer;
}

#job-delivery-detail-tab .tab-content{
  background: #ffffff;
  padding: 10px;
  margin-top: -1px;
}
#job-delivery-detail-tab > .tab-content{  
  border: 1px solid #bdc3c7;
}

.navigation-box {
  border: 0;
  background: rgba(41, 158, 41, 0.07);
  border-radius: 3px;
}
.navigation-box .box-header {
  background:#00a65a;
  color: #ffffff;
  border-radius: 3px 3px 0 0;
}
.navigation-box .box-footer {
  border-top: 0;
  background: transparent;
}

/*
* ==============================================================================
* Common
* ==============================================================================
*/
.m-0 { margin: 0px; }
.m-5 { margin: 5px; }
.m-10 { margin: 10px; }
.m-15 { margin: 15px; }

.p-10 { padding: 10px; }
.p-15 { padding: 15px; }

.mr-0 { margin-right: 0px; }
.mr-3 { margin-right: 3px; }
.mr-5 { margin-right: 5px; }
.mr-10 { margin-right: 10px; }
.mr-15 { margin-right: 15px; }
.mr-20 { margin-right: 20px; }
.mr-30 { margin-right: 30px; }

.ml-0 { margin-left: 0px; }
.ml-3 { margin-left: 3px; }
.ml-5 { margin-left: 5px; }
.ml-10 { margin-left: 10px; }
.ml-15 { margin-left: 15px; }
.ml-20 { margin-left: 20px; }
.ml-30 { margin-left: 30px; }

.mt-0 { margin-top: 0px; }
.mt-3 { margin-top: 3px; }
.mt-5 { margin-top: 5px; }
.mt-10 { margin-top: 10px; }
.mt-15 { margin-top: 15px; }
.mt-20 { margin-top: 20px; }
.mt-30 { margin-top: 30px; }

.mb-0 { margin-bottom: 0px; }
.mb-3 { margin-bottom: 3px; }
.mb-5 { margin-bottom: 5px; }
.mb-10 { margin-bottom: 10px; }
.mb-15 { margin-bottom: 15px; }
.mb-20 { margin-bottom: 20px; }
.mb-30 { margin-bottom: 30px; }

.pr-0 { padding-right: 0px; }
.pr-3 { padding-right: 3px; }
.pr-5 { padding-right: 5px; }
.pr-10 { padding-right: 10px; }
.pr-15 { padding-right: 15px; }
.pr-20 { padding-right: 20px; }
.pr-30 { padding-right: 30px; }

.pl-0 { padding-left: 0px; }
.pl-3 { padding-left: 3px; }
.pl-5 { padding-left: 5px; }
.pl-10 { padding-left: 10px; }
.pl-15 { padding-left: 15px; }
.pl-20 { padding-left: 20px; }
.pl-30 { padding-left: 30px; }

.pt-0 { padding-top: 0px; }
.pt-3 { padding-top: 3px; }
.pt-5 { padding-top: 5px; }
.pt-10 { padding-top: 10px; }
.pt-15 { padding-top: 15px; }
.pt-20 { padding-top: 20px; }
.pt-30 { padding-top: 30px; }

.pb-0 { padding-bottom: 0px; }
.pb-3 { padding-bottom: 3px; }
.pb-5 { padding-bottom: 5px; }
.pb-10 { padding-bottom: 10px; }
.pb-15 { padding-bottom: 15px; }
.pb-20 { padding-bottom: 20px; }
.pb-30 { padding-bottom: 30px; }

body {
  background-color: #ecf0f5;
}

hr {
  border-top: 1px solid #eeeeee;
}

.br {
  white-space: pre-wrap;
}

.text-pink {
  color: #ff4ff1;
}

.table-layout-fixed {
  table-layout: fixed;
}

.table-layout-fixed td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkbox.disabled label {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
}

/*
* ==============================================================================
* Assignment Box
* ==============================================================================
*/

.box-working .box-header,
.box-working .widget-user-header {
  color: #fff;
  background-color: #3c8dbc;
}

.box-finished .box-header,
.box-finished .widget-user-header {
  background-color: #e2e4ea;
}


.box-expired .box-header,
.box-expired .widget-user-header {
  color: #fff;
  background-color: #605ca8;
}

.box-working .box-header .btn-box-tool,
.box-expired .box-header .btn-box-tool {
  color: #fff;
}


.carousel-inner {
  overflow: inherit;
}


/* MHT Message */
.mht-message {
  margin: 6px 0;
}
.mht-message-body {
  padding: 8px 10px;
  word-break: break-all;
}
.mht-message-customer > .mht-message-body {
  background: #EDEDED;
}

.mht-message-staff .mht-message-body {
  background: #8CC5F6;
}
.mht-message-staff > .mht-message-footer {
  float: right;
}
.mht-message-footer {
  padding: 2px 10px;
}
.mht-message-read {
  padding-left: 10px;
}
.mht-message-read-icon {
  cursor: pointer;
}
.mht-message-read-open {
  color: #1FBF0C;
  cursor: not-allowed;
}
.mht-message-read-staff {
  cursor: not-allowed;
}
/* .panel-message {
  color: #333333;
  background: #8CC5F6;
  border: none;
  box-shadow: none;
  padding: 10px;
  margin: 10px 0 0 0;
  border-radius: 0;
}

.panel-message-customer {
  background: #EDEDED;
}

.panel-message > .panel-heading,
.panel-message > .panel-body {
  padding: 5px 15px;
  font-size: 14px;
}

.panel-message .panel-heading .sender {
  font-weight: bold;
  font-size: 1.1em;
}

.panel-message-footer {
  padding: 0 5px;
  border: none;
  background: none;
}

.panel-body {
  padding: 0 15px;
} */

.ta-color--light {
    color: #b0bec5;
}
.ta-color--active,
.ta-color--link {
    color: #2196f3;
}
.ta-color--warn {
    color: #fb8c00;
}
.ta-color--alert {
    color: #e53935;
}
.ta-color--confirm {
    color: #43a047
}


/* == Body Background == */
.content-wrapper {
    background-color: #eceff1;
}
/* == Global Tabs == */
.component-slider .nav-tabs li.active a,
.component-slider .nav-tabs li.active a:hover,
.component-slider .nav-tabs li.active a:focus {
    background-color: #eceff1;
}

