.ta-table-html {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%
}
.ta-table-html td {
    padding: 8px;
}

.ta-table {
    position: relative;
}

.ta-table,
.ta-table-header,
.ta-table-body,
.ta-table-footer,
.ta-table-row,
.ta-table-cell,
.ta-table-cellGroup {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
    box-sizing: border-box;
}

.ta-table,
.ta-table-header,
.ta-table-body,
.ta-table-footer {
    flex-direction: column;
    background-clip: border-box;
}

/*.ta-table {*/
/*    border: 1px solid rgb(144, 164, 174);*/
/*    border-radius: 4px;*/
/*    box-shadow: 0 3px 1px -2px rgba(144, 164, 174,.2), 0 2px 2px 0 rgba(144, 164, 174,.14), 0 1px 5px 0 rgba(144, 164, 174,.12);*/
/*}*/

.ta-table-html thead,
.ta-table-html tfoot,
.ta-table-header,
.ta-table-footer {
    background: #f5f6f7;
    position: relative;
}

.ta-table-html thead,
.ta-table-header {
    font-weight: bold;
    border-bottom: 1px solid #cfd8dc;
    z-index: 1;
}
.ta-table-header .ta-table-cell--light,
.ta-table-header .ta-table-cell--index {
    font-weight: normal;
}

.ta-table-body {
    align-content: flex-start;
    position: relative;
    z-index: 0;
    flex: 1;
}

.ta-table-body__rows {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 0;
    scroll-behavior: smooth;
}
.ta-table-body__rows--scroll-auto {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
}
.ta-table-body__rows--scroll-x {
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
}
.ta-table-body__rows--scroll-y {
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.ta-table-html tfoot,
.ta-table-footer {
    color: #b0bec5;
    border-top: 1px solid #cfd8dc;
    z-index: 2;
}

.ta-table-header--card {
    border-radius: 4px 4px 0 0;
}
.ta-table-footer--card {
    border-radius: 0 0 4px 4px;
}

.ta-table--flex,
.ta-table--flex .ta-table-body,
.ta-table-body--flex {
    flex: 1;
    /*max-height: 100%;*/
}

.ta-table--size-full {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
}



.ta-table-row {
    flex-direction: row;
}

.ta-table-cellGroup {
    flex: 1 1 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
    box-sizing: border-box;
    flex-direction: row;
}

.ta-table-header .ta-table-cell--border-top {
    border-top: 1px solid #cfd8dc;
}

.ta-table-html tbody tr,
.ta-table-body .ta-table-row {
    border-bottom: 1px solid #cfd8dc;
}

.ta-table-body .ta-table-row--selected {
    /*color: white;*/
    /*background: #3c8dbc;*/
    border-color: #42a5f5;
    background: #e3f2fd;
    /*box-shadow: inset -1px 0 0 1px #42a5f5, inset 1px 0 0 1px #42a5f5;*/
    /*box-shadow: inset 0 0 0 1px #42a5f5;*/
}

.ta-table-body .ta-table-row--selected .ta-table-cell {
    /*border-color: #bbdefb;*/
    border-color: rgba(66, 165, 245, 0.25);
}
.ta-table-body .ta-table-row--selected + .ta-table-row {
    border-top-color: #42a5f5;
}
.ta-table-body .ta-table-row--selected:last-child {
    border-color: #42a5f5;
}

.ta-table-cell {
    flex: 1 1 100%;
    color: #000000;
    flex-direction: row;
    cursor: default;
}

.ta-table--vertical-lines .ta-table-cell + .ta-table-cell,
.ta-table--vertical-lines .ta-table-cellGroup + .ta-table-cell,
.ta-table--vertical-lines .ta-table-cell + .ta-table-cellGroup {
    border-left: 1px solid #cfd8dc;
}
.ta-table-cell > div {
    display: block;
    box-sizing: border-box;
    position: relative;
    padding: 8px;
    width: 100%;
    outline: none;
    cursor: default;
}

.ta-table-cell.pb-0 > div {
    padding-bottom: 0;
}

.ta-table-header .ta-table-cell > div {
    padding: 0;
    margin: 8px;
    max-width: calc(100% - 16px);
}


.ta-table-cell--editable > div {
    cursor: text;
}
.ta-table-cell--editable > div:focus {
    /*border-radius: 4px;*/
    box-shadow: inset 0 0 0 2px #42a5f5;
}

.ta-table-cell--editable.ta-table-cell--error > div,
.ta-table-cell--editable.ta-table-cell--error > div:focus {
    box-shadow: inset 0 0 0 2px #CC0000;
}
.ta-table-cell--editable.ta-table-cell--error > div::after {
    content: 'Error';
    float:right;
    color: white;
    background: #CC0000;
    padding: 0 6px;
    border-radius: 4px;
}
.ta-table-cell--editable.ta-table-cell--error > div[data-error]::after {
    content: attr(data-error);
}

.ta-table-cell--light,
.ta-table-cell--index {
    color: #b0bec5;
    color: rgba(0,0,0,0.2);
}
.ta-table-row--selected .ta-table-cell--index {
    color: #42a5f5;
}
.ta-table-cell--index > div{
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.ta-table-cell--index.ta-table-cell--alert {
    color: red;
    font-weight: bold;
}
.ta-table-cell--right > div {
    text-align: right;
}
.ta-table-cell--center > div {
    text-align: center;
}
.ta-table-cell--valign-center {
    align-items:center;
}

.ta-table-cell > div > .ta-text--paragraph {
    margin-bottom: 0;
}

/* CK Editor */

.ta-table-cell > .ck-rounded-corners .ck.ck-editor__editable:not(.ck-editor__nested-editable),
.ta-table-cell > .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-rounded-corners {
    border-radius: 0;
}
.ta-table-cell > .ck {
    white-space: break-spaces !important;
}
.ta-table-cell > .ck.ck-focused {
    border-color: #42a5f5 !important;
    z-index: 4;
}
.ta-table-cell > .ck.ck-editor__editable_inline {
    padding: 0 7px !important;
}
.ta-table-cell > .ck.ta-ck--passive {
    padding: 1px 8px !important;
}
.ta-table-cell > .ck.ck-editor__editable_inline p,
.ta-table-cell > .ck.ta-ck--passive p {
    margin: 7px 0 !important;
}
.ta-table-cell > .ta-ck--off p {
    margin: 0 !important;
}

/* columns */

.ta-table-columns {
    height: 0;
    width: 100%;

    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
    box-sizing: border-box;
    flex-direction: row;

}
.ta-table-columns > div {
    flex: 1 1 100%;
}

.ta-table-column:after {
    content: ' ';
    position: absolute;
    border-left: 1px solid rgba(224, 224, 224, 0.4);
    margin-left: -1px;
    width: 0;
    top:0;
    bottom:0;
    z-index: 2;
}



/* Scroll */

.ta-table--scroll_xy {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
}
.ta-table--scroll_y {
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}
.ta-table--scroll_x {
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
}

/* Scroll - Header & Footer */

.ta-table--scroll_xy .ta-table-header,
.ta-table--scroll_y .ta-table-header {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    max-width: 100%;
}
.ta-table--scroll_xy .ta-table-footer,
.ta-table--scroll_y .ta-table-footer {
    position: sticky;
    bottom: 0;
    right:0;
    left:0;
}

/* Look */