.tooltip {
    z-index: -1000;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 4px;
    opacity: 1;
    /*transition: opacity 0.3s;*/
}

.bubble {
    white-space: nowrap;
    color: white;
    font-size: 12px;
    line-height: 12px;
    padding: 6px 8px;
    border-radius: 4px;
    background: #222d32;
    overflow: hidden;
    text-overflow: ellipsis;

}

.arrowUp {
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #222d32;
    margin: 0 auto;
    display: block;
}
.arrowDown {
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #222d32;
}
.arrowRight {
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid #222d32;
}
.arrowLeft {
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-right: 4px solid #222d32;
}
