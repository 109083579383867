.message {
  /* padding: 10px; */
  /* background: #f7f7f7; */
}

.message:not(:last-child) {
  border-bottom: solid 1px #CCC;
}

.message.mht-message .message-header {
  background-color: #f39c12;
  border-bottom-color: #f39c12;
}
.message.mht-message .message-footer {
  background-color: #F6B363;
  /* border-top-color: #F6B363; */
}
.message.mht-message .message-body {
  background-color: #F6B363;
}

.message .message-header {
  padding: 10px 10px 8px 10px;
  border-bottom: 1px solid #eee;
  background: #e4e8f1;
}
.message .message-body {
  color: #555;
  padding: 8px 10px 10px 10px;
  font-size: 0.85em;
}
.message .message-body p {
  margin: 0;
}
.message .message-footer {
  /* margin-top: 10px; */
  padding: 8px 10px 10px 10px;
  border-top: solid 1px #eee;
}

.message .message-footer .badge {
  cursor: pointer;
}

.message .user-block img {
  width: 35px;
  height: 35px;
}
.message .user-block .username {
  font-size: 13px;
  margin-left: 45px;
}

.message .user-block .message-meta {
  color: #666;
  font-size: 0.85em;
  font-weight: normal;
  margin-top: 3px;
}

.message .user-block .description {
  font-weight: 400;
  font-size: 12px;
  margin-left: 45px;
}
